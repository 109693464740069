import React from "react";

import Chip from "@mui/material/Chip";

interface IChipIndicator {
  id: string | number;
  keyId?: string | number;
  label: string | number;
  icon?: any;
  onDelete?: any;
}
const ChipIndicator: React.FC<IChipIndicator> = ({
  id,
  keyId,
  label,
  icon,
  onDelete: handleDelete,
}): JSX.Element => (
  <Chip
    icon={icon ? icon : undefined}
    key={keyId || id}
    label={label}
    size="small"
    sx={{ my: 1, ml: 1, backgroundColor: "white" }}
    variant="outlined"
    color="secondary"
    onDelete={handleDelete ? () => handleDelete(id) : undefined}
  />
);

export default ChipIndicator;
