import de from "./messages/de.json";
import en from "./messages/en.json";
import it from "./messages/it.json";
import fr from "./messages/fr.json";
import tr from "./messages/tr.json";

const messages: { [index: string]: any } = {
  de,
  en,
  it,
  fr,
  tr,
};

export default messages;
