import { schema } from "normalizr";

import { ausstattungsgruppeSchema } from "./Ausstattungsgruppe";

const ausstattungSchema = new schema.Entity(
  "ausstattung",
  { ausstattungsgruppe: ausstattungsgruppeSchema },
  { idAttribute: "id" }
);

const ausstattungListSchema = new schema.Array(ausstattungSchema);

export { ausstattungSchema, ausstattungListSchema };
