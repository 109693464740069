import { TVehicle } from "types";

export const getRate = (filter: any, vehicle: TVehicle): number => {
  const { finance } = vehicle;

  const depositPerMonth = filter.deposit / filter.runTime;

  const rates = finance.find(({ runtime }) => runtime === filter.runTime);
  if (!rates) return 0;

  let rate;
  switch (filter.type) {
    case "classicCarLoan":
      rate = rates.credit;
      break;
    case "balloonFinancing":
      const balloon = rates.balloon
        ? rates.balloon.find(([mileage, rate]) => mileage >= filter.mileage)
        : null;
      rate = balloon ? balloon[1] : 0;
      break;
    case "leasingMileage":
      const leasing = rates.leasing
        ? rates.leasing.find(([mileage, rate]) => mileage >= filter.mileage)
        : null;

      rate = leasing ? leasing[1] : 0;
      break;
  }

  const newRate = rate ? rate - depositPerMonth : 0;

  if (newRate) {
    return process.env.REACT_APP_MINIMUM_RATE &&
      newRate < parseInt(process.env.REACT_APP_MINIMUM_RATE)
      ? parseInt(process.env.REACT_APP_MINIMUM_RATE)
      : newRate;
  }

  return 0;
};
