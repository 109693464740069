import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Next from "@mui/icons-material/NavigateNext";

import { TUser } from "types";
import { CheckoutStore } from "stores";

import {
  NameForm,
  AddressForm,
  OriginForm,
  ContactForm,
} from "../../components";

import RegistrationSchema from "./RegistrationSchema";

const Registration = () => {
  const navigate = useNavigate();

  const {
    state: { buyerIsOwner = true, buyer = {}, owner = {} },
    dispatch,
  } = React.useContext(CheckoutStore);

  const [BuyerShowPreviousAddress, setBuyerShowPreviousAddress] =
    React.useState(false);

  const {
    register: BuyerPersonalRegister,
    control: BuyerPersonalControl,
    formState: { errors: BuyerPersonalErrors, isValid: BuyerPersonalisValid },
    watch: BuyerWatch,
    getValues: getBuyerValues,
  } = useForm<TUser>({
    mode: "all",
    defaultValues: buyer,
    resolver: yupResolver(RegistrationSchema),
  });
  const BuyerPersonalPayload = {
    register: BuyerPersonalRegister,
    control: BuyerPersonalControl,
    errors: BuyerPersonalErrors,
  };

  const BuyerResidentSince = BuyerWatch("address.residentSince");

  React.useEffect(() => {
    if (!BuyerResidentSince) return;
    const today: any = new Date();
    const minimumResidence = 3 * 365 * 24 * 60 * 60 * 1000;
    if (BuyerResidentSince.length > 0) {
      const movedIn: any = new Date(BuyerResidentSince);
      if (today - movedIn < minimumResidence) {
        setBuyerShowPreviousAddress(true);
      } else {
        setBuyerShowPreviousAddress(false);
      }
    }
  }, [BuyerResidentSince]);

  const {
    register: OwnerPersonalRegister,
    control: OwnerPersonalControl,
    formState: { errors: OwnerPersonalErrors, isValid: OwnerPersonalisValid },
  } = useForm<TUser>({
    mode: "onBlur",
    defaultValues: owner,
    resolver: yupResolver(RegistrationSchema),
  });

  const OwnerPersonalPayload = {
    register: OwnerPersonalRegister,
    control: OwnerPersonalControl,
    errors: OwnerPersonalErrors,
  };

  const submit = () => {
    const payload = getBuyerValues();
    dispatch({ type: "UPDATE_BUYER", payload });
    navigate("/checkout/disclosure");
  };

  const toggleBuyerIsOwner = () => {
    dispatch({ type: "UPDATE_BUYER_IS_OWNER", payload: !buyerIsOwner });
  };

  return (
    <>
      <Card raised={false}>
        <form noValidate>
          <CardContent>
            <Typography variant="h5">
              <FormattedMessage id="common.buyer" defaultMessage="Käufer" />
            </Typography>
            <NameForm {...BuyerPersonalPayload} errors={BuyerPersonalErrors} />
            <OriginForm
              {...BuyerPersonalPayload}
              errors={BuyerPersonalErrors}
            />
            <Typography variant="h6">
              <FormattedMessage
                id="common.address"
                defaultMessage="Anschrift"
              />
            </Typography>
            <AddressForm
              {...BuyerPersonalPayload}
              prefix="address."
              errors={BuyerPersonalErrors.address}
              showResidentSince={true}
            />
            <Typography variant="h6">
              <FormattedMessage id="common.contact" defaultMessage="Kontakt" />
            </Typography>
            <ContactForm
              {...BuyerPersonalPayload}
              prefix="contact."
              errors={BuyerPersonalErrors.contact}
            />
            {BuyerShowPreviousAddress && (
              <>
                <Typography variant="h6">
                  <FormattedMessage
                    id="common.formerAddress"
                    defaultMessage="Vorherige Anschrift"
                  />
                </Typography>
                <AddressForm
                  {...BuyerPersonalPayload}
                  prefix="previousAddress."
                  errors={BuyerPersonalErrors.address}
                  showResidentSince={false}
                />
              </>
            )}
          </CardContent>
          <CardContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={buyerIsOwner}
                  onChange={() => toggleBuyerIsOwner()}
                />
              }
              label={
                <FormattedMessage
                  id="common.buyerIsOwner"
                  defaultMessage="Der Käufer ist auch der spätere Halter"
                />
              }
            />
          </CardContent>
          {!buyerIsOwner && (
            <CardContent>
              <Typography variant="h5">
                <FormattedMessage id="common.holder" defaultMessage="Halter" />
              </Typography>
              <NameForm {...OwnerPersonalPayload} />
              <OriginForm
                {...OwnerPersonalPayload}
                errors={OwnerPersonalErrors}
              />
              <Typography variant="h6">
                <FormattedMessage
                  id="common.address"
                  defaultMessage="Anschrift"
                />
              </Typography>
              <AddressForm
                {...OwnerPersonalPayload}
                prefix="address."
                errors={OwnerPersonalErrors.address}
                showResidentSince={true}
              />
              <Typography variant="h6">
                <Typography variant="h6">
                  <FormattedMessage
                    id="common.contact"
                    defaultMessage="Kontakt"
                  />
                </Typography>
              </Typography>
              <ContactForm
                {...OwnerPersonalPayload}
                prefix="contact."
                errors={OwnerPersonalErrors.contact}
              />
            </CardContent>
          )}
          <CardActions sx={{ display: "flex", justifyContent: "right" }}>
            <Button
              variant="contained"
              disabled={
                !(
                  (buyerIsOwner && BuyerPersonalisValid) ||
                  (BuyerPersonalisValid && OwnerPersonalisValid)
                )
              }
              color="success"
              onClick={() => submit()}
              endIcon={<Next />}
            >
              <FormattedMessage id="common.next" defaultMessage="weiter" />
            </Button>
          </CardActions>
        </form>
      </Card>
    </>
  );
};

export default Registration;
