import React from "react";
import { useIntl } from "react-intl";

import Chip from "../../ChipIndicator";

interface IGetriebeIndicator {
  filter: number[] | string[];
  dispatch?: any;
}

const GetriebeIndicator = ({
  filter = [],
  dispatch,
}: IGetriebeIndicator): JSX.Element | null => {
  const { formatMessage: t } = useIntl();
  if (!filter.length) return null;

  const handleDelete = (getriebe: any) => {
    dispatch({
      type: "REMOVE_ID_FROM_FILTER",
      payload: { filter: "getriebe", id: getriebe },
    });
  };
  return (
    <>
      {filter.map((getriebe: string | number) => (
        <Chip
          id={getriebe}
          label={t({
            id: `getriebe.${getriebe}.label`,
          })}
          onDelete={() => handleDelete(getriebe)}
        />
      ))}
    </>
  );
};

export default GetriebeIndicator;
