import { FormattedMessage, useIntl } from "react-intl";
import { Controller } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MUISelect from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

interface IReactHookFormSelect {
  control: any;
  defaultValue?: any;
  error?: string;
  featured?: any;
  labelPrefix?: string;
  name: string;
  options: string[];
  prefix?: string;
  required?: boolean;
  sort?: boolean;
}

const ReactHookFormSelect = ({
  control = () => null,
  error,
  featured = [],
  labelPrefix = "",
  name = "test",
  options = ["a", "b", "c"],
  prefix = "",
  sort = true,
  required,
  defaultValue,
  ...rest
}: IReactHookFormSelect): JSX.Element => {
  const labelId = `${prefix}${name}-label`;
  return (
    <FormControl defaultValue={defaultValue} {...rest} error={!!error}>
      <InputLabel id={labelId}>
        <FormattedMessage id={`forms.${name}.label`} />
        {required && <span>&nbsp;*</span>}
      </InputLabel>
      <Controller
        control={control}
        name={`${prefix}${name}`}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
          formState,
        }) => (
          <Select
            {...{
              onChange,
              name,
              value,
              options,
              labelId,
              labelPrefix,
              featured,
              sort,
            }}
            inputProps={{
              sx: {
                backgroundColor: required ? "#00dd0011" : "transparent",
              },
            }}
          />
        )}
      />
      {error && (
        <FormHelperText>
          <FormattedMessage id={`forms.${name}.help.${error}`} />
        </FormHelperText>
      )}
    </FormControl>
  );
};

interface ISelect {
  featured?: any;
  inputProps?: any;
  labelId: string;
  labelPrefix?: string;
  name: string;
  onChange: any;
  options: any[];
  sort?: boolean;
  value: number | string | null;
}

export const Select: React.FC<ISelect> = ({
  featured = [],
  labelId = "test",
  labelPrefix = "",
  name = "test",
  onChange = () => null,
  options = [],
  sort = true,
  value = "",
  inputProps = {},
}): JSX.Element => {
  const { formatMessage: t } = useIntl();

  const featuredOptions: any[] = featured.map((option: any) => ({
    value: option,
    label: t({ id: `forms.${labelPrefix || name}.options.${option}` }),
  }));

  const otherOptions = options
    .filter((option) => !featured.includes(option))
    .map((option) => ({
      value: option,
      label: t({ id: `forms.${labelPrefix || name}.options.${option}` }),
    }))
    .sort(({ label: prev }, { label: curr }) =>
      sort ? prev.localeCompare(curr) : 1
    );

  return (
    <MUISelect {...{ onChange, value, labelId, inputProps }}>
      {featuredOptions.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
      {featuredOptions.length && <hr />}
      {otherOptions.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </MUISelect>
  );
};

export default ReactHookFormSelect;
