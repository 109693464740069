import { TVehicle } from "types";

interface IModellReducer {
  vehicles: TVehicle[];
  filter: any;
}

const ModellReducer = ({ vehicles, filter }: IModellReducer) => {
  if (!filter.modell.length) return vehicles;
  return vehicles.filter(
    ({
      modell: {
        id: modellId,
        marke: { id: markeId },
      },
    }) => {
      if (filter.marke && filter.marke.includes(markeId)) return true;
      return filter.modell.includes(modellId);
    }
  );
};

export default ModellReducer;
