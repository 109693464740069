import { FormattedMessage } from "react-intl";
import Typography from "@mui/material/Typography";

const Home = () => {
  return (
    <>
      <Typography variant="h1" color="primary">
        <FormattedMessage id="test.hello_world" defaultMessage="hello world!" />
      </Typography>
    </>
  );
};
export default Home;
