import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Box from "@mui/material/Box";
import { VehicleStore, CatalogStore, FilterStore } from "stores";

import List from "../../List";
import Filter from "../../Filter";

import GetriebeReducer from "./GetriebeReducer";
import GetriebeIndicator from "./GetriebeIndicator";

const GetriebeFilter: React.FC = (): JSX.Element | null => {
  const { state: vehicles } = React.useContext(VehicleStore);
  const { formatMessage: t } = useIntl();

  const {
    state: {
      filter: { getriebe: value = [] },
    },
    dispatch,
  } = React.useContext(FilterStore);

  const {
    state: {
      result: { getriebe: ids },
    },
  } = React.useContext(CatalogStore);

  React.useEffect(
    () => {
      dispatch({
        type: "ADD_REDUCER",
        payload: { key: "getriebe", value: GetriebeReducer },
      });
      dispatch({
        type: "ADD_INDICATOR",
        payload: { key: "getriebe", value: GetriebeIndicator },
      });
      dispatch({
        type: "INIT_FILTER",
        payload: { getriebe: [] },
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!vehicles || !ids) return null;

  const setValueHandler = (value: any) => {
    if (value.length === ids.length)
      // all variants are selected, so clean up the filter
      dispatch({ type: "UPDATE_FILTER", payload: { getriebe: [] } });
    else dispatch({ type: "UPDATE_FILTER", payload: { getriebe: value } });
  };

  const options = ids.map((id: any, name: string) => ({
    value: id,
    title: t({ id: `getriebe.${id}.label` }),
  }));

  const all = {
    title: t({ id: "common.alle", defaultMessage: "Alle" }),
  };

  const Component = (
    <Box width={1}>
      <List {...{ value, options, setValueHandler, all }} />
    </Box>
  );

  return (
    <Filter
      component={Component}
      title={
        <FormattedMessage
          id="filter.getriebe.label"
          defaultMessage="Getriebe"
        />
      }
    />
  );
};

export default GetriebeFilter;
