import { TReactHookForm } from "types";
import { ReactHookFormText } from "components";

import { Container } from "..";

const OriginForm = ({
  register,
  control,
  errors,
}: TReactHookForm): JSX.Element => {
  return (
    <Container>
      <ReactHookFormText
        register={register}
        name="dateOfBirth"
        error={errors?.dateOfBirth?.type}
        type="date"
      />
      <ReactHookFormText
        register={register}
        name="placeOfBirth"
        error={errors?.placeOfBirth?.type}
      />
      <ReactHookFormText
        register={register}
        name="nationality"
        error={errors?.nationality?.type}
      />
    </Container>
  );
};

export default OriginForm;
