import React from "react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

import CheckedCircleIcon from "@mui/icons-material/CheckCircleOutline";
import CircleIcon from "@mui/icons-material/Lens";

interface IListFilter {
  value: any;
  options: any;
  all?: {
    title: string;
    caption?: number | string;
  };
  setValueHandler: any;
  forceChecked?: boolean;
  color?: string;
}

interface IOption {
  title: string;
  hex: string;
  value: string;
  disabled?: boolean;
}

const ListFilter: React.FC<IListFilter> = ({
  value,
  options,
  all,
  forceChecked = false,
  setValueHandler: setValue,
  color = "inherit",
}): JSX.Element => {
  const handleToggle = (optionValue: any) => () => {
    const currentIndex = value.indexOf(optionValue);
    const newChecked = [...value];

    if (currentIndex === -1) {
      newChecked.push(optionValue);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setValue(newChecked);
  };
  const selectAll = () => setValue([]);
  return (
    <React.Fragment>
      {all && (
        <List>
          <ListItem key="all" role={undefined} dense button onClick={selectAll}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                style={{ color }}
                edge="start"
                checked={!value.length}
                tabIndex={-1}
                inputProps={{ "aria-labelledby": "checkbox-list-label-all" }}
              />
            </ListItemIcon>
            <ListItemText
              id="checkbox-list-label-all"
              primary={all.title}
              primaryTypographyProps={{
                variant: "body1",
                component: "span",
                sx: { textTransform: "capitalize" },
              }}
              secondary={all.caption}
              secondaryTypographyProps={{
                variant: "body2",
                align: "right",
              }}
            />
          </ListItem>
          <Divider />
        </List>
      )}
      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
        {options.map(
          ({ title, hex, value: optionValue, disabled = false }: IOption) => {
            const labelId = `checkbox-list-label-${optionValue}`;
            const color = hex;
            return (
              <ListItem
                key={labelId}
                role={undefined}
                onClick={handleToggle(optionValue)}
                disabled={disabled}
                sx={{ cursor: "pointer" }}
              >
                <ListItemIcon>
                  {(value.indexOf(optionValue) !== -1 && (
                    <CheckedCircleIcon sx={{ color }} />
                  )) || (
                    <CircleIcon
                      sx={{
                        color,
                      }}
                    />
                  )}
                  <ListItemText
                    primary={title}
                    primaryTypographyProps={{
                      variant: "caption",
                      sx: { ml: 1, overflow: "hidden" },
                    }}
                  />
                </ListItemIcon>
              </ListItem>
            );
          }
        )}
      </Box>
    </React.Fragment>
  );
};

export default ListFilter;
