import React from "react";
import { Helmet } from "react-helmet";

const Header: React.FC = (): JSX.Element => {
  const href = window.location.href;

  return (
    <Helmet>
      <title>carbando catalog</title>
      <meta property="og:title" content="carbando catalog" />
      <meta property="og:url" content={href} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content="carbando" />
    </Helmet>
  );
};

export default Header;
