import React from "react";
import { FormattedMessage } from "react-intl";

import Typography from "@mui/material/Typography";
import { TReactHookForm } from "types";
import { ReactHookFormText, ReactHookFormSelect } from "components";
import { Container } from "..";

import { AddressForm } from "..";
const EmploymentForm = ({
  register,
  control,
  errors,
  prefix = "employment.",
}: TReactHookForm): JSX.Element => {
  return (
    <>
      <Typography variant="h6">
        <FormattedMessage
          id="common.Arbeitsverhältnis"
          defaultMessage="Arbeitsverhältnis"
        />
      </Typography>
      <Container>
        <ReactHookFormSelect
          control={control}
          name="typeOfEmployment"
          labelPrefix="typeOfEmployment"
          prefix={prefix}
          required
          sort={false}
          error={errors?.typeOfEmployment?.type}
          options={["employed", "independently", "unemployed"]}
        />
        <ReactHookFormText
          register={register}
          name="occupation"
          prefix={prefix}
          error={errors?.occupation?.type}
        />
        <ReactHookFormText
          register={register}
          name="employedSince"
          prefix={prefix}
          error={errors?.employedSince?.type}
          required={false}
          type="date"
        />
        <ReactHookFormText
          register={register}
          name="companyName"
          prefix={prefix}
          error={errors?.companyName?.type}
        />
        <ReactHookFormSelect
          control={control}
          name="legalForm"
          required
          error={errors?.legalForm?.type}
          sort={false}
          prefix={prefix}
          labelPrefix="legalForm"
          options={[
            "ek",
            "gbr",
            "silentSociety",
            "openSociety",
            "generalPartnership",
            "limitedPartnership",
            "corporation",
            "GmbH",
            "eg",
          ]}
        />
        <ReactHookFormText
          register={register}
          name="annualTurnover"
          prefix={prefix}
          defaultValue={0}
          error={errors?.annualTurnover?.type}
          type="number"
          required={false}
        />
      </Container>
      <AddressForm
        prefix="employment.address."
        register={register}
        control={control}
        errors={errors?.address}
        showResidentSince={false}
      />
    </>
  );
};

export default EmploymentForm;
