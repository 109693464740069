import React from "react";

import Box from "@mui/material/Box";

import {
  AufbauFilter,
  AusstattungFilter,
  FarbeAussenFilter,
  FinanceFilter,
  FzartFilter,
  GetriebeFilter,
  KraftstoffFilter,
  MarkeModellFilter,
  LeistungFilter,
  TuerenFilter,
  SitzeFilter,
} from "./components/filters";

const Filter: React.FC = (): JSX.Element | null => {
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
        paddingRight: "1rem",
      }}
    >
      <FinanceFilter />
      <MarkeModellFilter />
      <AusstattungFilter />
      <AufbauFilter />
      <FzartFilter />
      <LeistungFilter />
      <GetriebeFilter />
      <KraftstoffFilter />
      <FarbeAussenFilter />
      {process.env.REACT_APP_VEHICLE_TYPE !== "krad" && <TuerenFilter />}
      {process.env.REACT_APP_VEHICLE_TYPE !== "krad" && <SitzeFilter />}
    </Box>
  );
};

export default Filter;
