import Cookies from "js-cookie";

const CookieReducer = (state: any, action: { type: any; payload: any }) => {
  const { type, payload = {} } = action;
  switch (type) {
    case "LOAD":
      return Cookies.get();

    case "SET":
      Cookies.set(payload.name, payload.value);
      return { ...state, [payload.name]: payload.value };

    case "DELETE":
      Cookies.remove(payload.name);
      delete state[payload.name];
      return { ...state };

    default:
      return state;
  }
};

export default CookieReducer;
