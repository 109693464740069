import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";

import { TPicture } from "types";

import { vehicle as defaultVehicle } from "fixtures";

import "./gallery.css";
import Typography from "@mui/material/Typography";
import { Hidden } from "@mui/material";

const defaultPicture = "https://cdn.carbando.eu/develop/logo512.png";

interface IGallery {
  paperProps: any;
  galleryProps: any;
  pictures?: TPicture[] | null;
}

const Gallery: React.FC<IGallery> = ({
  paperProps = {},
  galleryProps = {},
  pictures = defaultVehicle.pictures,
}): JSX.Element | null => {
  const [fullscreen, toggleFullscreen] = React.useState(false);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [swiperInstance, setSwiperInstance] = React.useState<any>(null);
  const [zoomSwiperInstance, setZoomSwiperInstance] = React.useState<any>(null);

  const zoomIn = () => {
    zoomSwiperInstance.slideTo(swiperInstance.clickedIndex, 0);
    toggleFullscreen(true);
  };

  const zoomOut = () => {
    swiperInstance.slideTo(currentSlide, 0);
    toggleFullscreen(false);
  };

  if (!pictures || pictures.length === 0)
    return <EmptyGallery {...{ paperProps }} />;

  return (
    <>
      <Paper {...paperProps}>
        <Swiper
          onSwiper={setSwiperInstance}
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          {...galleryProps}
          onClick={() => zoomIn()}
          onSlideChange={(event) => setCurrentSlide(event.activeIndex)}
          initialSlide={currentSlide}
        >
          {pictures.map(
            (
              { src, alt, position }: { src: any; alt: any; position: any },
              index: number
            ) => (
              <SwiperSlide key={`slide-${index}`}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    "&:hover": { cursor: "zoom-in" },
                  }}
                >
                  <img src={src} alt={alt} />
                </Box>
              </SwiperSlide>
            )
          )}
        </Swiper>
      </Paper>
      <Dialog
        keepMounted
        open={fullscreen}
        onClose={() => zoomOut()}
        fullScreen
      >
        <Swiper
          onSwiper={setZoomSwiperInstance}
          modules={[Navigation, Pagination, Keyboard]}
          navigation
          pagination={{ clickable: true }}
          slidesPerView={1}
          keyboard={{
            enabled: true,
          }}
          onSlideChange={(event) => setCurrentSlide(event.activeIndex)}
          initialSlide={currentSlide}
        >
          {pictures.map(
            (
              {
                src,
                alt,
                position,
              }: {
                src: string;
                alt?: string;
                position: number;
              },
              index: number
            ) => (
              <SwiperSlide key={`slide-${index}`}>
                <img src={src} alt={alt} />
              </SwiperSlide>
            )
          )}
        </Swiper>
        <IconButton
          onClick={() => zoomOut()}
          aria-label="close"
          color="secondary"
          size="large"
          sx={{
            position: "absolute",
            top: "1vh",
            right: "1vw",
            zIndex: 101,
          }}
        >
          <Avatar sx={{ border: 1 }} color="primary">
            <CloseIcon />
          </Avatar>
        </IconButton>
        ^
      </Dialog>
    </>
  );
};

interface IEmptyGallery {
  paperProps: any;
}
const EmptyGallery: React.FC<IEmptyGallery> = ({ paperProps }): JSX.Element => (
  <Hidden lgDown>
    <Paper {...paperProps}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pt: 2,
        }}
      >
        <img src={defaultPicture} alt="" width="200" />
        <Typography variant="h5" sx={{ mt: 2 }}>
          Bilder folgen in Kürze
        </Typography>
      </Box>
    </Paper>
  </Hidden>
);

export default Gallery;
