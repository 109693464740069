import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Box from "@mui/material/Box";
import { VehicleStore, CatalogStore, FilterStore } from "stores";

import List from "./List";
import Filter from "../../Filter";

import { FarbeAussenReducer } from "./FarbeReducer";
import FarbeIndicator from "./FarbeIndicator";

const FarbeAussenFilter: React.FC = (): JSX.Element | null => {
  const { state: vehicles } = React.useContext(VehicleStore);
  const { formatMessage: t } = useIntl();

  const {
    state: {
      filter: { farbe_aussen: value = [] },
    },
    dispatch,
  } = React.useContext(FilterStore);

  const {
    state: {
      result: { farbe: ids },
    },
  } = React.useContext(CatalogStore);

  React.useEffect(
    () => {
      dispatch({
        type: "ADD_REDUCER",
        payload: { key: "farbe_aussen", value: FarbeAussenReducer },
      });
      dispatch({
        type: "ADD_INDICATOR",
        payload: { key: "farbe_aussen", value: FarbeIndicator },
      });
      dispatch({
        type: "INIT_FILTER",
        payload: { farbe_aussen: [] },
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!vehicles || !ids) return null;

  const setValueHandler = (value: any) => {
    if (value.length === ids.length) {
      // all variants are selected, so clean up the filter
      dispatch({ type: "UPDATE_FILTER", payload: { farbe_aussen: [] } });
    } else {
      dispatch({ type: "UPDATE_FILTER", payload: { farbe_aussen: value } });
    }
  };

  const options = ids.map((id: number) => ({
    value: id,
    title: t({ id: `farbe.${id}.label` }),
    hex: t({ id: `farbe.${id}.hex` }),
  }));
  const all = {
    title: t({ id: "common.alle", defaultMessage: "Alle" }),
  };

  const Component = (
    <Box width={1}>
      <List {...{ value, options, setValueHandler, all }} />
    </Box>
  );

  return (
    <Filter
      component={Component}
      title={
        <FormattedMessage
          id="filter.farbe_aussen.label"
          defaultMessage="Außenfarbe"
        />
      }
    />
  );
};

export { FarbeAussenFilter };
