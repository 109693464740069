import React from "react";
import { useIntl } from "react-intl";
import Chip from "../../ChipIndicator";

interface IAusstattungIndicator {
  filter: number[] | string[];
  dispatch?: any;
}

const AusstattungIndicator = ({
  filter = [],
  dispatch,
}: IAusstattungIndicator): JSX.Element | null => {
  const { formatMessage: t } = useIntl();
  if (!filter.length) return null;

  const handleDelete = (ausstattung: any) => {
    dispatch({
      type: "REMOVE_ID_FROM_FILTER",
      payload: { filter: "ausstattung", id: ausstattung },
    });
  };
  return (
    <>
      {filter.map((ausstattung: string | number) => (
        <Chip
          id={ausstattung}
          key={ausstattung}
          label={t({
            id: `ausstattung.${ausstattung}.label`,
          })}
          onDelete={() => handleDelete(ausstattung)}
        />
      ))}
    </>
  );
};

export default AusstattungIndicator;
