import React from "react";
import { Helmet } from "react-helmet";

import { CatalogStore } from "stores";

interface IHeader {
  modellId: string;
}

const Header: React.FC<IHeader> = ({ modellId = "5469" }): JSX.Element => {
  const href = window.location.href;
  const {
    state: { entities },
  } = React.useContext(CatalogStore);
  const modell = entities.modell[modellId];
  const marke = entities.marke[modell.marke];

  return (
    <Helmet>
      <title>{`${marke.name} ${modell.name}`}</title>
      <meta property="og:title" content={`${marke.name} ${modell.name}`} />
      <meta property="og:url" content={href} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content="carbando" />
    </Helmet>
  );
};

export default Header;
