import React from "react";
import { useNavigate } from "react-router-dom";
import { rollups, extent, min, sum } from "d3-array";

import { TVehicle } from "types";

import { VehicleStore, FilterStore } from "stores";
import Card from "./ModellCard";
import Box from "@mui/material/Box";

import Header from "./Header";

const Modelle: React.FC = (): JSX.Element | null => {
  const { state: vehicles } = React.useContext(VehicleStore);
  const { state: filters } = React.useContext(FilterStore);
  const sortOrder = filters.filter.finance?.type === "lease" ? "rate" : "preis";

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  if (!vehicles || vehicles.length === 0) return null;

  const groups = groupVehicles(vehicles).sort((a: any, b: any) => {
    return b[1][sortOrder] > a[1][sortOrder] ? -1 : 1;
  });

  return (
    <>
      <Header />
      <Box
        sx={{
          display: "grid",
          columnGap: { xs: 1, sm: 2, md: 3 },
          rowGap: { xs: 2, md: 3, lg: 4 },
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          },
        }}
      >
        {groups.map(([id, group]: [string, string]) => {
          return (
            <Card
              key={id}
              data={group}
              variant={sortOrder}
              onClick={() => navigate("./" + id)}
              paperProps={{
                sx: {
                  borderRadius: 1,
                  backgroundColor: "grey.300",
                  ":hover": {
                    borderColor: "grey.800",
                    backgroundColor: "grey.300",
                    cursor: "pointer",
                  },
                },
              }}
            />
          );
        })}
      </Box>
    </>
  );
};

const groupVehicles = (vehicles: TVehicle[]): any => {
  const groupedVehicles = rollups(
    vehicles,
    (v: any) => ({
      picture:
        v[0].pictures.length > 0 && v[0].pictures[0].src
          ? v[0].pictures[0].src
          : "",
      modell: v[0].modell,
      vorrat: sum(v, (d: any) => 1),
      rate: min(v, (d: any) => d.rate),
      preis: min(v, (d: any) => d.preis),

      co2_effizienzklasse: extent(v, ({ co2_effizienzklasse }: any) =>
        check(co2_effizienzklasse)
      ),
      co2_emissionen: extent(v, (d: any) => d.co2_emissionen),
      verbrauch_kombiniert: extent(v, ({ verbrauch_kombiniert }: any) =>
        check(verbrauch_kombiniert)
      ),
      stromverbrauch_kombiniert: extent(
        v,
        ({ stromverbrauch_kombiniert }: any) => check(stromverbrauch_kombiniert)
      ),
    }),
    (d: any) => d.modell.id
  );
  return groupedVehicles;
};

const check = (d: any) => (d ? d : null);

export default Modelle;
