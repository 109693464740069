import { TVehicle } from "types";

interface ILeistungReducer {
  vehicles: TVehicle[];
  filter: any;
}

const LeistungReducer = ({ vehicles, filter }: ILeistungReducer) => {
  if (!filter.leistung) return vehicles;

  return vehicles.filter(({ kw: leistung }) => {
    return (
      leistung &&
      filter.leistung[0] <= leistung &&
      filter.leistung[1] >= leistung
    );
  });
};

export default LeistungReducer;
