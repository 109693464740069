import React, { ReactNode, useReducer } from "react";

import VehiclesReducer from "./VehicleReducer";
import { TVehicles } from "./types";

import { CatalogStore, FilterStore } from "stores";

type TVehiclesProvider = {
  children: ReactNode;
};

const emptyVehiclesStore: TVehicles = [];

export const VehicleStore = React.createContext<{
  state: TVehicles;
  dispatch: React.Dispatch<any>;
}>({
  state: emptyVehiclesStore,
  dispatch: () => null,
});

const VehicleProvider = ({ children }: TVehiclesProvider) => {
  const [state, dispatch] = useReducer(VehiclesReducer, emptyVehiclesStore);
  const { state: catalog } = React.useContext(CatalogStore);
  const { state: filters } = React.useContext(FilterStore);

  React.useEffect(() => {
    dispatch({ type: "CALCULATE", payload: { catalog, filters } });
  }, [catalog, filters]);

  return (
    <VehicleStore.Provider value={{ state, dispatch }}>
      {children}
    </VehicleStore.Provider>
  );
};

export default VehicleProvider;
