import React from "react";
import Markdown from "react-markdown";
import { FormattedMessage, useIntl } from "react-intl";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import CookieIcon from "@mui/icons-material/CookieTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RuleTwoToneIcon from "@mui/icons-material/RuleTwoTone";
import ThumbDownAltTwoToneIcon from "@mui/icons-material/ThumbDownAltTwoTone";
import ThumbUpAltTwoToneIcon from "@mui/icons-material/ThumbUpAltTwoTone";
import HelpIcon from "@mui/icons-material/HelpOutline";

import { CookieStore } from "stores";

const Wall: React.FC = (): JSX.Element | null => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(["required", "statistics"]);

  const { state: cookies, dispatch } = React.useContext(CookieStore);

  const { formatMessage: t } = useIntl();

  React.useEffect(() => {
    setOpen(!cookies.consent);
  }, [cookies]);

  const saveSetting = (which: string) => {
    dispatch({
      type: "SET",
      payload: {
        name: "consent",
        value: true,
      },
    });

    switch (which) {
      case "decline": {
        break;
      }
      case "confirmSelection": {
        break;
      }
      case "selectAll": {
        break;
      }
    }
    setOpen(false);
  };

  const handleChange = (option: string) => {
    if (options.includes(option)) {
      setOptions(options.filter((e: string) => e !== option));
    } else {
      setOptions([...options, option]);
    }
  };

  if (cookies?.consent) return null;
  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>
        <Stack direction="row" alignItems="center" gap={1}>
          <CookieIcon fontSize="large" color="info" />
          <Typography variant="h6">
            <FormattedMessage
              id="cookieConsent.headline"
              defaultMessage="Cookie Einstellungen"
            />
          </Typography>
          <Tooltip title={t({ id: "cookieConsent.cookieDescription" })}>
            <HelpIcon sx={{ fontSize: "0.8em", mt: -2 }} />
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" component="span">
          <Markdown>{t({ id: "cookieConsent.description" })}</Markdown>
        </Typography>
        <Option
          value="required"
          onChange={handleChange}
          checked={options.includes("required")}
          color="info"
          disabled
        />
        <Option
          value="statistics"
          onChange={handleChange}
          checked={options.includes("statistics")}
        />
        <Option
          value="preferences"
          onChange={handleChange}
          checked={options.includes("preferences")}
        />
        <Option
          value="marketing"
          onChange={handleChange}
          checked={options.includes("marketing")}
        />
      </DialogContent>
      <DialogActions
        sx={{
          px: 3,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Button
          variant="outlined"
          color="error"
          startIcon={<ThumbDownAltTwoToneIcon />}
          onClick={() => {
            saveSetting("decline");
          }}
        >
          <FormattedMessage
            id="cookieConsent.button.decline"
            defaultMessage="Ablehnen"
          />
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<RuleTwoToneIcon />}
          onClick={() => {
            saveSetting("confirmSelection");
          }}
          sx={{ my: 2 }}
        >
          <FormattedMessage
            id="cookieConsent.button.confirmSelection"
            defaultMessage="Auswahl bestätigen"
          />
        </Button>
        <Button
          variant="outlined"
          color="success"
          startIcon={<ThumbUpAltTwoToneIcon />}
          onClick={() => {
            saveSetting("selectAll");
          }}
        >
          <FormattedMessage
            id="cookieConsent.button.selectAll"
            defaultMessage="Alle auswählen"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface IOption {
  value: string;
  checked: boolean;
  disabled?: boolean;
  color?: any;
  onChange: any;
}

const Option: React.FC<IOption> = ({
  checked,
  value,
  color = "success",
  disabled = false,
  onChange,
}): JSX.Element => {
  const stopPropagation = (event: any) => {
    if (disabled) return;
    onChange(value);
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <FormControlLabel
          onClick={stopPropagation}
          control={
            <Switch
              {...{ value, checked, disabled, color, onChange }}
              onClick={stopPropagation}
            />
          }
          label={
            <FormattedMessage
              id={`cookieConsent.options.${value}.label`}
              defaultMessage={value}
            />
          }
        />
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2">
          <FormattedMessage
            id={`cookieConsent.options.${value}.description`}
            defaultMessage={value}
          />
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default Wall;
