import React from "react";
import { FormattedMessage } from "react-intl";

import TextField from "@mui/material/TextField";

interface IReactHookFormText {
  name: string;
  register: any;
  error?: any;
  required?: boolean;
  type?: string;
  prefix?: string;
  defaultValue?: string | number;
  inputProps?: any;
}
const ReactHookFormText: React.FC<IReactHookFormText> = ({
  register = () => null,
  name,
  error = "",
  required = true,
  type = "text",
  prefix = "",
  defaultValue,
  inputProps = {},
}): JSX.Element => {
  return (
    <TextField
      {...register(`${prefix}${name}`)}
      label={<FormattedMessage id={`forms.${name}.label`} />}
      error={!!error}
      defaultValue={defaultValue}
      type={type}
      helperText={
        error && <FormattedMessage id={`forms.${name}.help.${error}`} />
      }
      InputLabelProps={{
        shrink: ["date", "number"].includes(type) || undefined,
      }}
      inputProps={{
        ...inputProps,
        sx: {
          backgroundColor: required ? "#00dd0011" : "transparent",
        },
      }}
      required={required}
    />
  );
};

export default ReactHookFormText;
