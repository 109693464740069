import React from "react";

import Typography from "@mui/material/Typography";
import { TLocation } from "types";

interface ITopicDealer {
  location?: any;
}

const TopicDealer: React.FC<ITopicDealer> = ({
  location = defaultLocation,
}): JSX.Element => (
  <Typography gutterBottom variant="body1">
    {location.name}
    <br /> {location.address}
    <br /> {location.zip}&nbsp;{location.city}
    <br /> {location.phone}
    <br />
  </Typography>
);

const defaultLocation: TLocation = {
  id: 1,
  name: "carbando GmbH",
  address: "Ballindamm 13",
  zip: "20095",
  city: "Hamburg",
  phone: "+49 (40) 600899950",
};

export default TopicDealer;
