import React from "react";
import { useIntl } from "react-intl";

import Chip from "../../ChipIndicator";

interface IFinanceIndicator {
  filter: any;
}

const defaultValue = {
  type: "buy",
  classicCarLoan: true,
  balloonFinancing: true,
  leasingMileage: true,
  deposit: 0,
  mileage: 10000,
  price: [0, 20000],
  rate: [0, 500],
  runTime: 48,
};

const FinanceIndicator = ({
  filter = defaultValue,
}: IFinanceIndicator): JSX.Element | null => {
  const { formatMessage: t, formatNumber: n } = useIntl();

  if (!filter) return null;
  let label = "";
  if (filter.type === "lease") {
    const [from, to] = filter.rate;
    label = t(
      {
        id: "filter.financial.indicator.lease.label",
        defaultMessage: "Rate",
      },
      { from, to }
    );
    if (filter.deposit > 0) {
      label +=
        " | " +
        t({
          id: "filter.financial.deposit.title.label",
          defaultMessage: "Anzahlung",
        }) +
        ": " +
        t({
          id: "common.upTo",
          defaultMessage: "Anzahlung",
        }) +
        " " +
        n(filter.deposit, {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "EUR",
        });
    }
    if (filter.runTime > 0) {
      label +=
        " | " +
        t({
          id: "common.upTo",
          defaultMessage: "Anzahlung",
        }) +
        " " +
        t(
          {
            id: "filter.financial.runningTime.summary.label",
            defaultMessage: "Laufzeit",
          },
          { value: filter.runTime }
        );
    }
    if (filter.mileage > 0) {
      label +=
        " | min. " + n(filter.mileage, { style: "unit", unit: "kilometer" });
    }
  } else {
    const [from, to] = filter.price;
    label = t(
      {
        id: "filter.financial.indicator.buy.label",
        defaultMessage: "Finanzierung",
      },
      { from, to }
    );
  }

  return <Chip id="finance" key="finance" label={label} />;
};

export default FinanceIndicator;
