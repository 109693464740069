import React from "react";
import Markdown from "react-markdown";

import { IntlContext } from "intl";

interface IExtMd {
  topic: string;
}

const ExtMd: React.FC<IExtMd> = ({ topic = "agb" }): JSX.Element | null => {
  const { locale } = React.useContext(IntlContext);

  const customer = process.env.REACT_APP_CUSTOMER;

  // Fetch data
  const [data, setData] = React.useState<any>(null);
  React.useEffect(() => {
    const dataFetch = async () => {
      const request = await fetch(
        `https://api.carbando.eu/common/${customer}/${topic}`,

        {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Accept-Language": locale || "de",
          },
        }
      );
      const result = await request.text();
      setData(result);
    };

    dataFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic, locale]);

  if (!data) return null;

  return <Markdown>{data}</Markdown>;
};

export default ExtMd;
