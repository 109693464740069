import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Accordion";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Next from "@mui/icons-material/NavigateNext";
import Before from "@mui/icons-material/NavigateBefore";

import { TSelfDisclosure } from "types";
import { CheckoutStore } from "stores";

import {
  LivingConditionsForm,
  FinancialBalanceForm,
  EmploymentForm,
  IdCardForm,
  BankForm,
} from "../../components";

import DisclosureSchema from "./DisclosureSchema";

const Disclosure = () => {
  const navigate = useNavigate();

  const {
    state: {
      buyer: { selfdisclosure = {} },
    },
    dispatch,
  } = React.useContext(CheckoutStore);

  const {
    register: BuyerFinancialRegister,
    control: BuyerFinancialControl,
    formState: { errors: BuyerFinancialErrors, isValid: BuyerFinancialisValid },
    getValues,
  } = useForm<TSelfDisclosure>({
    mode: "all",
    defaultValues: selfdisclosure,
    resolver: yupResolver(DisclosureSchema),
  });

  const BuyerFinancialPayload = {
    register: BuyerFinancialRegister,
    control: BuyerFinancialControl,
    errors: BuyerFinancialErrors,
  };

  const submit = () => {
    const payload = getValues();
    dispatch({ type: "UPDATE_BUYER_SELFDISCLOSURE", payload });
    navigate("/checkout/summary");
  };

  return (
    <>
      <Card>
        <form noValidate>
          <CardContent>
            <Typography variant="h5">
              <FormattedMessage
                id="checkout.disclosure.selbstauskunft.label"
                defaultMessage="Selbstauskunft"
              />
            </Typography>
            <LivingConditionsForm
              {...BuyerFinancialPayload}
              prefix="livingConditions."
              errors={BuyerFinancialErrors.livingConditions}
            />
            <Typography variant="h6">
              <FormattedMessage
                id="checkout.disclosure.einkommen.label"
                defaultMessage="Einkommen"
              />
            </Typography>
            <FinancialBalanceForm
              {...BuyerFinancialPayload}
              prefix="financialBalance."
              errors={BuyerFinancialErrors.financialBalance}
            />
            <EmploymentForm
              {...BuyerFinancialPayload}
              prefix="employment."
              errors={BuyerFinancialErrors.employment}
            />
            <Typography variant="h6">
              <FormattedMessage
                id="checkout.disclosure.ausweis.label"
                defaultMessage="Ausweis"
              />
            </Typography>
            <IdCardForm
              {...BuyerFinancialPayload}
              prefix="idCard."
              errors={BuyerFinancialErrors.idCard}
            />
            <Typography variant="h6">
              <FormattedMessage
                id="checkout.disclosure.bankverbindung.label"
                defaultMessage="Bankverbindung"
              />
            </Typography>
            <BankForm
              {...BuyerFinancialPayload}
              prefix="bankAccount."
              errors={BuyerFinancialErrors.bankAccount}
            />
          </CardContent>
          <CardActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              variant="contained"
              component={Link}
              to="/checkout/registration"
              color="success"
              startIcon={<Before />}
            >
              <FormattedMessage id="common.back" defaultMessage="zurück" />
            </Button>

            <Button
              sx={{ ml: 2 }}
              variant="contained"
              disabled={!BuyerFinancialisValid}
              color="success"
              onClick={() => submit()}
              endIcon={<Next />}
            >
              <FormattedMessage id="common.next" defaultMessage="weiter" />
            </Button>
          </CardActions>
        </form>
      </Card>
    </>
  );
};

export default Disclosure;
