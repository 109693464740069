import React from "react";
import { useIntl } from "react-intl";

import Chip from "../../ChipIndicator";

interface ILeistungIndicator {
  filter: [number, number] | number | null;
  dispatch?: any;
}

const LeistungIndicator = ({
  filter,
  dispatch,
}: ILeistungIndicator): JSX.Element | null => {
  const { formatMessage: t } = useIntl();

  if (!filter) return null;

  const label =
    Array.isArray(filter) && filter[0] !== filter[1]
      ? t(
          {
            id: "common.kw.span",
            defaultMessage: "{from} bis {to} kW",
          },
          { from: filter[0], to: filter[1] }
        )
      : t(
          {
            id: "common.kw.plural",
            defaultMessage: "{count, plural, one {ein kW} other {# kW}}",
          },
          { count: Array.isArray(filter) ? filter[0] : filter }
        );

  const handleDelete = (leistung: any) => {
    dispatch({
      type: "UPDATE_FILTER",
      payload: { leistung: null },
    });
  };

  return (
    <Chip
      id="leistung"
      label={label}
      onDelete={() => handleDelete("leistung")}
    />
  );
};

export default LeistungIndicator;
