import { denormalize } from "normalizr";
import { vehicleListSchema } from "schemas";

import { json2normalized } from "./helper";

import catalogData from "./catalog.json";
import catalogFinanceTestData from "./catalog_finance_test.json";

export const catalog = json2normalized(catalogData);
export const catalogFinanceTest = json2normalized(catalogFinanceTestData);

export const vehicles = denormalize(
  Object.keys(catalog.entities.vehicle),
  vehicleListSchema,
  catalog.entities
);

export const vehiclesFinanceTest = denormalize(
  Object.keys(catalogFinanceTest.entities.vehicle),
  vehicleListSchema,
  catalogFinanceTest.entities
);
