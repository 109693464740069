import React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { CatalogStore } from "stores";

const Breadcrumb: React.FC = (): JSX.Element | null => {
  const { pathname } = useLocation();
  const {
    state: { entities },
  } = React.useContext(CatalogStore);

  if (!entities?.vehicle) return null;

  const breadcrumb = pathname2breadcrumb(pathname, entities);

  return (
    <Box
      sx={{
        px: 1,
        bgcolor: "grey.100",
        typography: "body2",
        display: { xs: "none", md: "block" },
      }}
    >
      <Container maxWidth={"xl"}>
        <Stack
          direction="row"
          justifyContent="left"
          alignItems="center"
          divider={<ArrowForwardIosIcon fontSize="inherit" />}
        >
          {breadcrumb.map(
            ({ label, link }: { label: any; link: string }, ndx: number) => {
              return (
                <Button
                  disabled={ndx === breadcrumb.length - 1}
                  component={Link}
                  to={link}
                  key={ndx}
                  color="primary"
                >
                  {label}
                </Button>
              );
            }
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default Breadcrumb;

const pathname2breadcrumb = (pathname: string, entities: any): any => {
  const paths = pathname.split("/");
  paths.shift();
  const environment = paths.shift();
  const breadcrumb = [];
  breadcrumb.push({
    label: <FormattedMessage id={"navigation." + environment} />,
    link: `/${environment}`,
  });
  switch (environment) {
    case "checkout": {
      breadcrumb.push({
        label: <FormattedMessage id={"navigation." + paths[0]} />,
        link: ".",
      });
      break;
    }

    case "catalog": {
      switch (paths.length) {
        case 1: {
          const modell = entities.modell[paths[0]];
          const marke = entities.marke[modell.marke];
          breadcrumb.push({
            label: marke.name + " " + modell.name,
            link: [environment, paths[0]].join("/"),
          });
          break;
        }

        case 2: {
          const modell = entities.modell[paths[0]];
          const marke = entities.marke[modell.marke];
          breadcrumb.push({
            label: marke.name + " " + modell.name,
            link: [environment, paths[0]].join("/"),
          });

          const vehicle = entities.vehicle[paths[1]];
          breadcrumb.push({
            label: vehicle.version,
            link: [environment, ...paths].join("/"),
          });
          break;
        }
      }

      break;
    }
    default: {
    }
  }
  return breadcrumb;
};
