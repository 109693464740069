import { TReactHookForm } from "types";
import { ReactHookFormText, ReactHookFormSelect } from "components";
import { Container } from "..";

const IdCardForm = ({
  register,
  control,
  errors,
  prefix = "idCard.",
}: TReactHookForm): JSX.Element => {
  return (
    <Container>
      <ReactHookFormSelect
        control={control}
        name="idCardType"
        required
        error={errors?.idCardType?.type}
        prefix={prefix}
        labelPrefix="idCardType"
        options={["personal", "travel"]}
      />
      <ReactHookFormText
        register={register}
        name="idCardNumber"
        prefix={prefix}
        error={errors?.idCardNumber?.type}
      />
      <ReactHookFormText
        register={register}
        name="issuingAuthority"
        prefix={prefix}
        error={errors?.issuingAuthority?.type}
      />
    </Container>
  );
};

export default IdCardForm;
