// import fs from "fs";

interface Iarray2normalized {
  input: any[];
  id?: string;
}

export const array2normalized = ({
  input,
  id = "id",
}: Iarray2normalized): any => {
  const output: any = {};
  for (const element of input) {
    const idValue = element[id];
    output[idValue] = element;
  }
  return output;
};

// export const dump2file = (path: string, content: any) => {
//   fs.writeFile(path, content, (err) => {
//     if (err) {
//       console.error(err);
//     }
//   });
// };

interface IJson {
  [key: string]: any[];
}

interface IStore {
  entities: IJson;
  result: {
    [key: string]: number | string[];
  };
}

export const json2normalized = (json: IJson): IStore => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const entities = <IJson>{};
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const result = <IJson>{};

  for (const [key, input] of Object.entries(json)) {
    entities[key] = array2normalized({ input });
    result[key] = input.map(({ id }: { id: number | string }) => id);
  }

  return { entities, result };
};
