import React from "react";

import { getColorCode } from "./colors";

const allLevels = ["A+++", "A++", "A+", "A", "B", "C", "D", "E", "F", "G"];

interface IEnergyLabel {
  barHeight?: number;
  base: string;
  baseWidth: number;
  indicator: string;
  svgWidth: number;
}

const EnergyLabel: React.FC<IEnergyLabel> = ({
  baseWidth = 70,
  barHeight = 20,
  base = "A+",
  indicator = "A",
  svgWidth = 360,
}): JSX.Element => {
  const levels = allLevels.slice(allLevels.indexOf(base));
  return (
    <svg height={((levels.length * barHeight) / 2) * 3 + 10} width={svgWidth}>
      <g transform="translate(0,10)">
        {levels.map((level, ndx) => (
          <Bar key={ndx} {...{ level, ndx, base, baseWidth, barHeight }} />
        ))}
        {indicator && (
          <Indicator
            {...{
              indicator,
              ndx: levels.indexOf(indicator),
              baseWidth,
              barHeight,
              svgWidth,
            }}
          />
        )}
      </g>
    </svg>
  );
};

interface IBar {
  barHeight?: number;
  base?: any;
  baseWidth?: number;
  level?: any;
  ndx?: number;
}

const Bar: React.FC<IBar> = ({
  ndx = 1,
  level = "A+",
  base = "A+",
  baseWidth = 70,
  barHeight = 20,
}): JSX.Element => {
  const width = baseWidth + ndx * barHeight;
  return (
    <g transform={`translate(0 ${ndx * ((barHeight / 2) * 3)})`}>
      <polygon
        points={`0,0 ${width},0 ${width + barHeight / 2},${
          barHeight / 2
        } ${width},${barHeight} 0,${barHeight}`}
        fill={getColorCode(level, base)}
      />
      <text
        fontSize={barHeight * 0.8}
        fill="#FFFFFF"
        x={5}
        y={barHeight - barHeight / 5}
        fontFamily="Arial"
      >
        {level}
      </text>
    </g>
  );
};

interface IIndicator {
  barHeight?: number;
  baseWidth?: number;
  ndx: number;
  indicator?: string;
  svgWidth?: number;
}
const Indicator: React.FC<IIndicator> = ({
  ndx,
  baseWidth = 70,
  barHeight = 20,
  indicator = "A",
  svgWidth = 360,
}): JSX.Element => {
  const indicatorWidth = 50 + barHeight;
  return (
    <g transform={`translate(0 ${ndx * ((barHeight / 2) * 3)})`}>
      <polygon
        points={`${svgWidth},-5 ${svgWidth - indicatorWidth},-5 ${
          svgWidth - indicatorWidth - 5 - barHeight / 2
        },${barHeight / 2} ${svgWidth - indicatorWidth},${
          barHeight + 5
        } ${svgWidth},${barHeight + 5}`}
        fill="#000000"
      />
      <text
        textAnchor="end"
        fontSize={barHeight * 1.2}
        fill="#FFFFFF"
        x={svgWidth - 5}
        y={barHeight + 3 - barHeight / 5}
        fontFamily="Arial"
        fontWeight="bold"
      >
        {indicator}
      </text>
    </g>
  );
};

export default EnergyLabel;
