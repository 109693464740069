import { TVehicle } from "types";

interface IFinanceReducer {
  vehicles: TVehicle[];
  filter: any;
}

const FinanceReducer = ({ vehicles, filter }: IFinanceReducer) => {
  if (!filter.finance) return vehicles;
  const { type, price } = filter.finance;
  const calculatedVehicles =
    filter.finance.rate === false
      ? vehicles
      : vehicles.map((vehicle) => {
          if (!vehicle.finance) return vehicle;

          const finance = vehicle.finance.sort((a, b) =>
            b.runtime > a.runtime ? 1 : -1
          );

          const rates = finance.filter(
            ({ runtime }) => runtime <= filter.finance.runTime
          );
          if (!rates) return vehicle;
          const creditRates =
            filter.finance.classicCarLoan &&
            rates
              .map((e: any) =>
                e.credit ? e.credit - filter.finance.deposit / e.runtime : null
              )
              .filter((e) => !!e);

          const balloonRates =
            filter.finance.balloonFinancing &&
            rates
              .map((e: any) =>
                e.balloon
                  ? e.balloon
                      .filter(
                        ([m, r]: [number, number]) =>
                          m >= filter.finance.mileage
                      )
                      .map(
                        ([m, r]: [number, number]) =>
                          r - filter.finance.deposit / e.runtime
                      )
                  : null
              )
              .flat()
              .filter((e: number) => !!e);

          const leasingRates =
            filter.finance.leasingMileage &&
            rates
              .map((e: any) =>
                e.leasing
                  ? e.leasing
                      .filter(
                        ([m, r]: [number, number]) =>
                          m >= filter.finance.mileage
                      )
                      .map(
                        ([m, r]: [number, number]) =>
                          r - filter.finance.deposit / e.runtime
                      )
                  : null
              )
              .flat()
              .filter((e: number) => !!e);

          const filteredRates: any = [creditRates, balloonRates, leasingRates]
            .flat()
            .filter((e: number) => !!e)
            .map((rate) =>
              process.env.REACT_APP_MINIMUM_RATE &&
              rate < parseInt(process.env.REACT_APP_MINIMUM_RATE)
                ? parseInt(process.env.REACT_APP_MINIMUM_RATE)
                : rate
            )
            .filter(
              (rate) =>
                rate &&
                rate >= filter.finance.rate[0] &&
                rate <= filter.finance.rate[1]
            );

          if (!filteredRates.length) return vehicle;

          const lowestRate = Math.min(...filteredRates);
          if (!lowestRate) return vehicle;

          vehicle.rate = lowestRate;
          return vehicle;
        });

  if (type === "lease") {
    return calculatedVehicles.filter(
      ({ rate }) =>
        rate && rate >= filter.finance.rate[0] && rate <= filter.finance.rate[1]
    );
  } else if (type === "buy") {
    return calculatedVehicles.filter(
      ({ preis }) => preis >= price[0] && preis <= price[1]
    );
  }
};

export default FinanceReducer;
