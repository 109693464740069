import React from "react";

import { CartItem } from ".";
import { CartStore } from "stores";

const Cart: React.FC = (): JSX.Element | null => {
  const { state: Cart } = React.useContext(CartStore);

  if (!Cart?.vehicles || Cart.vehicles.length === 0) return null;
  return (
    <>
      {Cart.vehicles.map(({ id, finance }) => (
        <CartItem key={id} {...{ id, finance }} />
      ))}
    </>
  );
};

export default Cart;
