import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
const Error404 = () => {
  return (
    <Box
      sx={{
        height: "40vh",
        width: "100%",
        display: "grid",
        justifyItems: "center",
        alignContent: "center",
      }}
      typography="h4"
    >
      <FormattedMessage
        id="pages.404.title"
        defaultMessage="Seite nicht gefunden."
      />
      <Button
        variant="contained"
        size="small"
        to="/"
        component={Link}
        sx={{ mt: 2 }}
      >
        zur Startseite
      </Button>
    </Box>
  );
};
export default Error404;
