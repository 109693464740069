import React from "react";
import Box from "@mui/material/Box";

interface IBackdrop {
  url: string;
}
const Backdrop: React.FC<IBackdrop> = ({
  url = "https://img01.carliner.de/WD/hdata/h130/default_boerse/ww_10016_1.jpg",
}): JSX.Element => (
  <Box
    sx={{
      backgroundImage: `url(${url})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "100vw",
      height: "100vh",
      position: "fixed",
      left: 0,
      filter: "blur(10px)",
      opacity: 0.2,
      zIndex: -1,
    }}
  />
);

export default Backdrop;
