import * as yup from "yup";

const DisclosureSchema = yup.object().shape({
  livingConditions: yup.object().shape({
    maritalStatus: yup.string().required(),
    propertyType: yup.string().required(),
    dependentChildren: yup.number().required(),
  }),

  financialBalance: yup.object().shape({
    netIncome: yup.number().required(),
    additionalIncome: yup.number().required(),
    loading: yup.number(),
    maintenanceObligations: yup.number().required(),
    otherCreditObligations: yup.number().required(),
  }),

  employment: yup.object().shape({
    typeOfEmployment: yup.string().required(),
    occupation: yup.string().required(),
    employedSince: yup.string().required(),
    companyName: yup.string().required(),
    legalForm: yup.string().required(),
    annualTurnover: yup.number(),
    address: yup.object().shape({
      street: yup.string().required(),
      houseNumber: yup.string().required(),
      additionalAddress: yup.string(),
      zipCode: yup.string().required(),
      city: yup.string().required(),
      country: yup.string().required(),
    }),
  }),

  idCard: yup.object().shape({
    idCardType: yup.string().required(),
    idCardNumber: yup.string().required(),
    issuingAuthority: yup.string().required(),
  }),

  bankAccount: yup.object().shape({
    iban: yup
      .string()
      .required()
      .matches(/^\w{2}\d{2}\d{8}\d{10}$/),
    bic: yup.string().matches(/^\w{11}$/),
    kto: yup.number(),
    blz: yup.number(),
  }),
});

export default DisclosureSchema;
