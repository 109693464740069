import { schema } from "normalizr";

import { markeSchema } from "./Marke";

const modellSchema = new schema.Entity(
  "modell",
  { marke: markeSchema },
  { idAttribute: "id" }
);

export { modellSchema };
