import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";

import { CartStore } from "stores";

import { Registration, Disclosure, Summary, Conclusion } from "./containers";

import { Cart } from "./components";

const Checkout = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate replace to="registration" />} />
        <Route path="registration" element={<Registration />} />
        <Route path="disclosure" element={<Disclosure />} />
        <Route path="summary" element={<Summary />} />
        <Route path="conclusion" element={<Conclusion />} />
        <Route path="*" element={<Navigate replace to="registration" />} />
      </Route>
    </Routes>
  );
};

const steps = [
  "/checkout/registration",
  "/checkout/disclosure",
  "/checkout/summary",
  "/checkout/conclusion",
];

const Layout = (): JSX.Element | null => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    state: { vehicles },
  } = React.useContext(CartStore);

  const go = (index: number) => {
    navigate(steps[index], { replace: true });
  };
  if (vehicles.length === 0) {
    navigate("/catalog", { replace: true });
    return null;
  }

  const isPurchase = vehicles[0].finance.type === "buy";
  let activeStep = steps.indexOf(pathname) || 0;
  if (isPurchase && activeStep === 1) {
    go(2);
  } else if (isPurchase && activeStep > 1) {
    activeStep -= 1;
  }
  return (
    <>
      <Cart />
      <Stepper sx={{ my: 3 }} nonLinear activeStep={activeStep}>
        <Step>
          <StepButton onClick={() => go(0)}>
            <FormattedMessage
              id="checkout.step.registrierung.label"
              defaultMessage="Registrierung"
            />
          </StepButton>
        </Step>
        {!isPurchase && (
          <Step>
            <StepButton
              disabled={activeStep < 1}
              color="inherit"
              onClick={() => go(1)}
            >
              <FormattedMessage
                id="checkout.step.selbstauskunft.label"
                defaultMessage="Selbstauskunft"
              />
            </StepButton>
          </Step>
        )}
        <Step>
          <StepButton
            disabled={activeStep < 2}
            color="inherit"
            onClick={() => go(2)}
          >
            <FormattedMessage
              id="checkout.step.zusammenfassung.label"
              defaultMessage="Zusammenfassung"
            />
          </StepButton>
        </Step>
        <Step>
          <StepButton
            disabled={activeStep < 3}
            color="inherit"
            onClick={() => go(3)}
          >
            <FormattedMessage
              id="checkout.step.abschluss.label"
              defaultMessage="Abschluss"
            />
          </StepButton>
        </Step>
      </Stepper>
      <Outlet />
    </>
  );
};
export default Checkout;

// const Validator = () => {
//   const [valid, setValid] = React.useState(false);
//   const {
//     state: { buyer },
//   } = useContext(CheckoutStore);

//   RegistrationSchema.validate(buyer)
//     .then(() => setValid(true))
//     .catch(() => setValid(false));

//   return <span>{(valid && "valid") || "invalid"}</span>;
// };
