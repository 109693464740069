import React, { useEffect, useState } from "react";

const defaultConfiguration = { footer: { social: {} } };
const getConfigurarion = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_CDN_SERVER}/${process.env.REACT_APP_CUSTOMER}/configuration.json`
  );
  if (response.status !== 200) {
    return {};
  }
  let data = await response.json();
  return { ...defaultConfiguration, ...data };
};

export const ConfigurationStore = React.createContext(defaultConfiguration);

const Configuration: React.FC = ({ children }): JSX.Element => {
  const [configuration, setConfigurarion] = useState(defaultConfiguration);

  useEffect(() => {
    const loadConfiguration = async () => {
      const data = await getConfigurarion();
      setConfigurarion(data);
    };

    loadConfiguration();
  }, []);

  return (
    <ConfigurationStore.Provider value={configuration}>
      {children}
    </ConfigurationStore.Provider>
  );
};

export default Configuration;
