import React from "react";

import { FormattedMessage } from "react-intl";

import { TVehicle } from "types";
import { vehicle as defaultVehicle } from "fixtures";
import Box from "@mui/material/Box";

interface ITopicTechniques {
  vehicle: TVehicle;
}

const TopicTechniques: React.FC<ITopicTechniques> = ({
  vehicle: { fzart, aufbau, getriebe, tueren, sitze, kw } = defaultVehicle,
}): JSX.Element => (
  <Box
    sx={{
      typography: "body2",
      color: "secondary.main",
      display: "grid",
      columnGap: 3,
      rowGap: 1,
      gridTemplateColumns: {
        xs: "repeat(1, 1fr)",
        lg: "repeat(2, 1fr)",
      },
    }}
  >
    <div>
      <FormattedMessage id="common.enginePower" defaultMessage="Leistung" />
      &nbsp;|&nbsp;
      {kw} kW
    </div>
    {getriebe && (
      <div>
        <FormattedMessage
          id={`techniques.gearType.${getriebe.name}`}
          defaultMessage={getriebe.name}
        />
      </div>
    )}
    <div>
      <FormattedMessage
        id={`fzart.${fzart.id}.label`}
        defaultMessage={fzart.name}
      />
    </div>
    {tueren && (
      <div>
        <FormattedMessage id="techniques.tueren" values={{ tueren }} />
      </div>
    )}
    {sitze && (
      <div>
        <FormattedMessage id="techniques.sitze" values={{ sitze }} />
      </div>
    )}
    {aufbau && (
      <div>
        <FormattedMessage
          id={`techniques.vehicleType.${aufbau.name}`}
          defaultMessage={aufbau.name}
        />
      </div>
    )}
  </Box>
);

export default TopicTechniques;
