import React, { ReactNode, useReducer } from "react";

import CookieReducer from "./CookieReducer";

import { CookieStoreType } from "./types";

type TCookieProvider = {
  config?: { type: string };
  children: ReactNode;
};

const initialState: CookieStoreType = [{}, () => {}];
const emptyVehiclesStore: any = {};

export const CookieStore = React.createContext<{
  state: any;
  dispatch: React.Dispatch<any>;
}>({
  state: emptyVehiclesStore,
  dispatch: () => null,
});

const CookieProvider = ({
  config = { type: "test" },
  children,
}: TCookieProvider) => {
  const [state, dispatch] = useReducer(CookieReducer, initialState[0]);
  React.useEffect(() => {
    getData(config)(dispatch);
  }, [config]);

  return (
    <CookieStore.Provider value={{ state, dispatch }}>
      {children}
    </CookieStore.Provider>
  );
};

export default CookieProvider;

const getData =
  ({ type, payload }: { type: string; payload?: any }): any =>
  async (dispatch: any) => {
    switch (type) {
      case "test":
        dispatch({ type: "LOAD" });
        break;
      default:
        dispatch({ type: "LOAD" });
    }
  };
