import React from "react";

import { CatalogStore } from "stores";

import Chip from "../../ChipIndicator";

interface IMarkeIndicator {
  filter: number[];
  dispatch?: any;
}

const MarkeIndicator = ({
  filter,
  dispatch,
}: IMarkeIndicator): JSX.Element | null => {
  const {
    state: {
      entities: { marke },
    },
  } = React.useContext(CatalogStore);
  if (!filter.length) return null;

  const handleDelete = (marke: any) => {
    dispatch({
      type: "REMOVE_ID_FROM_FILTER",
      payload: { filter: "marke", id: marke },
    });
  };
  return (
    <>
      {filter.map((id: number) => (
        <Chip
          id={id}
          label={marke[id].name}
          onDelete={() => handleDelete(id)}
        />
      ))}
    </>
  );
};

export default MarkeIndicator;
