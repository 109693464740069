import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { carbandoTheme, getCustomerTheme } from "themes";

interface ITheme {
  theme?: string;
}

const Theme: React.FC<ITheme> = ({ theme, children }): JSX.Element => {
  const [currentTheme, setTheme] = useState(carbandoTheme);

  useEffect(() => {
    const loadCustomerTheme = async () => {
      const customerTheme = await getCustomerTheme();
      setTheme(customerTheme);
    };

    if (process.env.REACT_APP_THEME === "customer") loadCustomerTheme();
  }, []);

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
