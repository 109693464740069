import React from "react";
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { CheckoutStore } from "stores";

const Conclusion: React.FC = (): JSX.Element | null => {
  const {
    state: { buyer },
  } = React.useContext(CheckoutStore);

  let location = useLocation();

  if (!buyer) return null;
  const id = location.search
    ? new URLSearchParams(location.search).get("id")
    : "cb-20220802-33494";
  const customer = process.env.REACT_APP_CUSTOMER;

  return (
    <Box>
      <Box mt={4} mb={2}>
        <Typography variant="h5" color="secondary">
          <FormattedMessage
            id="common.Bestellbestätigung"
            defaultMessage="Bestellbestätigung"
          />
        </Typography>
      </Box>
      <Card variant="outlined" square>
        <CardContent>
          <Typography variant="body2">
            <p>Sehr gehhrter Herr {buyer.lastName},</p>
            <p>
              vielen Dank für Ihre Bestellung.
              <br />
              Sie haben es fast geschafft.
            </p>
            <p>
              Nun sollten Sie Ihre Bestellung gut durchlesen und uns diese bitte
              unterschrieben zurücksenden
            </p>

            <Button
              href={`https://s3.eu-central-1.amazonaws.com/transaction.carbando.eu/${customer}/${id}/contract.pdf`}
              variant="contained"
              color="secondary"
              target="_new"
            >
              PDF Daten zur Bestellung
            </Button>
            <p>
              In Kürze erhalten sie zusätzlich eine Email mit der
              Bestellbestätigung und den wichtigsten Daten.
            </p>
            <p>
              Bei Fragen nutzen Sie gerne unsere Servicehotline
              <br />
              Servicehotline +49 30 398 39 39 0
            </p>
          </Typography>
        </CardContent>
      </Card>

      <Box mt={4} mb={2} textAlign="right"></Box>
    </Box>
  );
};

export default Conclusion;
