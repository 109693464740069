import React from "react";
import { FormattedMessage } from "react-intl";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { EnergyLabel, Tupel } from "components";

import { TVehicle } from "types";
import { vehicle as defaultVehicle } from "fixtures";

interface IEnergy {
  paperProps?: any;
  vehicle: TVehicle;
}

const Energy: React.FC<IEnergy> = ({
  paperProps = { sx: { p: 2 } },
  vehicle = defaultVehicle,
}): JSX.Element | null => {
  if (!vehicle) return null;
  return (
    <Paper variant="outlined" {...paperProps}>
      <Typography variant="h5">
        <FormattedMessage id="common.umwelt" defaultMessage="Umwelt" />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ typography: "body1" }}>
          {vehicle.verbrauch_kombiniert > 0 && (
            <Tupel
              labelId="term.consumption.combined"
              float={vehicle.verbrauch_kombiniert}
              unit="l/100km"
              footnote={1}
            />
          )}
          {vehicle.verbrauch_stadt > 0 && (
            <Tupel
              labelId="term.consumption.inner"
              float={vehicle.verbrauch_stadt}
              unit="l/100km"
              footnote={1}
            />
          )}
          {vehicle.verbrauch_land > 0 && (
            <Tupel
              labelId="term.consumption.outer"
              float={vehicle.verbrauch_land}
              unit="l/100km"
              footnote={1}
            />
          )}
          {vehicle.stromverbrauch_kombiniert > 0 && (
            <Tupel
              labelId="term.consumptionElectric.combined"
              float={vehicle.stromverbrauch_kombiniert}
              unit="kWh/100km"
              footnote={1}
            />
          )}
          {vehicle.co2_emissionen > 0 && (
            <Tupel
              labelId="term.co2Emission.combined"
              number={vehicle.co2_emissionen}
              unit="g/100km"
              footnote={1}
            />
          )}

          {vehicle.co2_effizienzklasse && (
            <Tupel
              labelId="term.energyEfficiencyClass"
              text={vehicle.co2_effizienzklasse}
            />
          )}
          {vehicle.schadstoff && (
            <Tupel
              labelId="page.detail.technicalData.emissionClass"
              text={vehicle.schadstoff.name}
            />
          )}
          {vehicle.umweltplakette && (
            <Tupel
              labelId="page.detail.technicalData.pollutionBadge"
              text={vehicle.umweltplakette.name}
            />
          )}
        </Grid>
        {vehicle.co2_effizienzklasse && (
          <Grid item xs={12} md={6}>
            <EnergyLabel
              svgWidth={330}
              base="A+++"
              baseWidth={50}
              indicator={vehicle.co2_effizienzklasse}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default Energy;
