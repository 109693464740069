import React from "react";
import { useIntl } from "react-intl";

import Chip from "../../ChipIndicator";

interface IKraftstoffIndicator {
  filter: number[] | string[];
  dispatch?: any;
}

const KraftstoffIndicator = ({
  filter = [],
  dispatch,
}: IKraftstoffIndicator): JSX.Element | null => {
  const { formatMessage: t } = useIntl();
  if (!filter.length) return null;

  const handleDelete = (kraftstoff: any) => {
    dispatch({
      type: "REMOVE_ID_FROM_FILTER",
      payload: { filter: "kraftstoff", id: kraftstoff },
    });
  };
  return (
    <>
      {filter.map((kraftstoff: string | number) => (
        <Chip
          id={kraftstoff}
          label={t({
            id: `kraftstoff.${kraftstoff}.label`,
          })}
          onDelete={() => handleDelete(kraftstoff)}
        />
      ))}
    </>
  );
};

export default KraftstoffIndicator;
