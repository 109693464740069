import React, { ReactNode, useReducer } from "react";

import { checkout } from "fixtures";
import CheckoutReducer from "./CheckoutStoreReducer";
import { TCheckoutStore } from "./types";

type TCheckoutStoreProvider = {
  config?: { type: string; payload?: any };
  children: ReactNode;
};

const emptyCheckoutStore = {} as TCheckoutStore;
export const CheckoutStore = React.createContext<{
  state: TCheckoutStore;
  dispatch: React.Dispatch<any>;
}>({
  state: emptyCheckoutStore,
  dispatch: () => null,
});

const CheckoutStoreProvider = ({
  config = { type: "prod" },
  children,
}: TCheckoutStoreProvider) => {
  const [state, dispatch] = useReducer(CheckoutReducer, emptyCheckoutStore);
  React.useEffect(() => {
    getData(config)(dispatch);
  }, [config]);

  return (
    <CheckoutStore.Provider value={{ state, dispatch }}>
      {children}
    </CheckoutStore.Provider>
  );
};

const getData =
  ({ type, payload }: { type: string; payload?: any }): any =>
  async (dispatch: any) => {
    switch (type) {
      case "test":
        dispatch({
          type: "INIT",
          payload: checkout,
        });
        break;
      default:
        dispatch({
          type: "INIT",
          payload: {
            buyerIsOwner: true,
            buyer: {},
            owner: {},
          } as TCheckoutStore,
        });
    }
  };

export default CheckoutStoreProvider;
