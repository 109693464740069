import { denormalize } from "normalizr";

import { TVehicles, TCatalog, TFilter } from "stores/types";

import { vehicleListSchema } from "schemas";

const VehiclesReducer = (
  state: TVehicles,
  action: { type: any; payload: { catalog: TCatalog; filters: TFilter } }
) => {
  const { type, payload } = action;
  switch (type) {
    case "CALCULATE":
      const {
        catalog: {
          entities,
          result: { vehicle },
        },
        filters: { filter, reducer },
      } = payload;
      let vehicles = denormalize(vehicle, vehicleListSchema, entities);
      if (vehicles) {
        Array.from(
          reducer,
          ([key, f]: [string | number, any]) =>
            (vehicles = f({ vehicles, filter }))
        );
      }
      return vehicles;

    default:
      return state;
  }
};

export default VehiclesReducer;
