import { TReactHookForm } from "types";
import { ReactHookFormText } from "components";
import { Container } from "..";

const BankForm = ({
  register,
  control,
  errors,
  prefix = "bankAccount.",
}: TReactHookForm): JSX.Element => {
  return (
    <Container>
      <ReactHookFormText
        register={register}
        name="iban"
        prefix={prefix}
        error={errors?.iban?.type}
      />
      <ReactHookFormText
        register={register}
        name="bic"
        required={false}
        prefix={prefix}
        error={errors?.bic?.type}
      />
    </Container>
  );
};

export default BankForm;
