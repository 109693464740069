import { schema } from "normalizr";

const qualitaetssiegelSchema = new schema.Entity(
  "qualitaetssiegel",
  {},
  { idAttribute: "id" }
);

const qualitaetssiegelListSchema = new schema.Array(qualitaetssiegelSchema);

export { qualitaetssiegelSchema, qualitaetssiegelListSchema };
