import Box from "@mui/material/Box";

const Container: React.FC = ({ children }): JSX.Element => (
  <Box
    sx={{
      display: "grid",
      my: {
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
      },
      gap: {
        xs: 2,
        md: 3,
        lg: 4,
      },
      gridTemplateColumns: {
        xs: "repeat(1, 1fr)",
        sm: "repeat(3, 1fr)",
        md: "repeat(4, 1fr)",
        lg: "repeat(5, 1fr)",
      },
    }}
  >
    {children}
  </Box>
);
export default Container;
