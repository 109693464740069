import { TVehicle } from "types";

interface IGetriebeReducer {
  vehicles: TVehicle[];
  filter: any;
}

const GetriebeReducer = ({ vehicles, filter }: IGetriebeReducer) => {
  if (!filter.getriebe.length) return vehicles;
  return vehicles.filter(({ getriebe }) => {
    return getriebe && filter.getriebe && filter.getriebe.includes(getriebe.id);
  });
};

export default GetriebeReducer;
