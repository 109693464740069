import React from "react";
import { FormattedMessage } from "react-intl";

import GoogleMapReact from "google-map-react";
import Markdown from "react-markdown";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RoomIcon from "@mui/icons-material/Room";

import { TVehicle, TLocation } from "types";

import { vehicle as defaultVehicle } from "fixtures";

interface IDealer {
  paperProps?: any;
  vehicle: TVehicle;
}

interface IAnyComponent {
  lat: number;
  lng: number;
  text: any;
}

const Marker: React.FC<IAnyComponent> = ({ lat, lng, text }): JSX.Element => {
  return (
    <a
      href={`http://www.google.com/maps/place/${lat},${lng}`}
      target="_blank"
      rel="noreferrer"
    >
      <RoomIcon fontSize="large" color="primary" />
    </a>
  );
};

const Dealer: React.FC<IDealer> = ({
  paperProps = { sx: { p: 2 } },
  vehicle = defaultVehicle,
}): JSX.Element | null => {
  if (!vehicle.location) return null;
  const { location } = vehicle;

  const {
    name,
    address,
    zip,
    city,
    phone,
    lat = 52.520008,
    lng = 13.404954,
    email = "",
    freetext = "",
  }: TLocation = location;
  return (
    <Paper variant="outlined" {...paperProps}>
      <Typography variant="h5">
        <FormattedMessage id="common.dealer" defaultMessage="Händler" />
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <Box sx={{ height: 300, width: { xs: 310, md: 400 } }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "",
              }}
              defaultCenter={{
                lat: 52.520008,
                lng: 13.404954,
              }}
              defaultZoom={14}
            >
              {lat && lng && <Marker {...{ lat, lng }} text="My Marker" />}
            </GoogleMapReact>
          </Box>
        </Grid>
        <Grid item xs={6}>
          Standort {name}
          <br />
          {address}
          <br /> {zip}&nbsp;{city}
          <br />
          {phone && phone}
          <br />
          {email && email}
          {freetext && <Markdown>{freetext}</Markdown>}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Dealer;
