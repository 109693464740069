import { schema } from "normalizr";

const ausstattungsgruppeSchema = new schema.Entity(
  "ausstattungsgruppe",
  {},
  { idAttribute: "id" }
);

const ausstattungsgruppeListSchema = new schema.Array(ausstattungsgruppeSchema);

export { ausstattungsgruppeSchema, ausstattungsgruppeListSchema };
