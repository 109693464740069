import { denormalize } from "normalizr";
import { vehicleSchema } from "schemas";

import { json2normalized } from "fixtures/helper";

import kradDetailJSON from "./krad_detail.json";
import kradCatalog from "./krad_catalog.json";

const { vehicle, ...rest } = kradDetailJSON;

const { entities } = json2normalized(rest);

const kradDetail = denormalize(vehicle, vehicleSchema, entities);

export { kradDetail, kradCatalog };
