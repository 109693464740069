import React from "react";
import { FormattedMessage } from "react-intl";

import { TReactHookForm } from "types";
import { ReactHookFormText, ReactHookFormCheckbox } from "components";

import Typography from "@mui/material/Typography";

import { Container } from "..";

const FinancialBalanceForm = ({
  register,
  control,
  errors,
  prefix = "financialBalance.",
}: TReactHookForm): JSX.Element => {
  return (
    <>
      <Container>
        <ReactHookFormText
          register={register}
          name="netIncome"
          defaultValue={0}
          prefix={prefix}
          error={errors?.netIncome?.type}
          type="number"
          inputProps={{ step: 100, min: 0 }}
        />
        <ReactHookFormText
          register={register}
          name="additionalIncome"
          defaultValue={0}
          prefix={prefix}
          error={errors?.additionalIncome?.type}
          required={false}
          inputProps={{ step: 100, min: 0 }}
          type="number"
        />
      </Container>
      <Typography variant="h6">
        <FormattedMessage
          id="common.sonstigeEinnahmen"
          defaultMessage="Sonstige Einnahmen"
        />
      </Typography>
      <Container>
        <ReactHookFormCheckbox
          control={control}
          name="additionalIncomeTypes.childBenefit"
        />
        <ReactHookFormCheckbox
          control={control}
          name="additionalIncomeTypes.rentalIncome"
        />
        <ReactHookFormCheckbox
          control={control}
          name="additionalIncomeTypes.sideline"
        />
        <ReactHookFormCheckbox
          control={control}
          name="additionalIncomeTypes.others"
        />
      </Container>

      <Container>
        <ReactHookFormText
          register={register}
          defaultValue={0}
          name="loading"
          prefix={prefix}
          error={errors?.loading?.type}
          required={false}
          inputProps={{ step: 100, min: 0 }}
          type="number"
        />
        <ReactHookFormText
          register={register}
          defaultValue={0}
          name="maintenanceObligations"
          prefix={prefix}
          error={errors?.maintenanceObligations?.type}
          required={true}
          inputProps={{ step: 100, min: 0 }}
          type="number"
        />
        <ReactHookFormText
          register={register}
          defaultValue={0}
          name="otherCreditObligations"
          prefix={prefix}
          error={errors?.otherCreditObligations?.type}
          required={true}
          inputProps={{ step: 100, min: 0 }}
          type="number"
        />
      </Container>
    </>
  );
};

export default FinancialBalanceForm;
