import React from "react";
import { useIntl } from "react-intl";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";

import { TReactHookForm } from "types";
import { ReactHookFormText, ReactHookFormIntPhone } from "components";

import { Container } from "..";

const ContactForm = ({
  register,
  control,
  errors,
  prefix = "contact.",
}: TReactHookForm): JSX.Element => {
  const { formatMessage: t } = useIntl();
  return (
    <Container>
      <ReactHookFormText
        register={register}
        name="email"
        prefix={prefix}
        error={errors?.email?.type}
      />
      <Box sx={{ gridColumn: "span 2" }}>
        <ReactHookFormIntPhone
          control={control}
          name="phone"
          prefix={prefix}
          required
          error={errors?.phone?.type}
        />

        <Tooltip title={t({ id: "forms.phone.information" })}>
          <HelpIcon sx={{ ml: 1, mt: 2 }} />
        </Tooltip>
      </Box>
    </Container>
  );
};

export default ContactForm;
