import { TCheckoutStore } from "./types";

const CheckoutStoreReducer = (
  state: TCheckoutStore,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;
  let newState;

  switch (type) {
    case "INIT":
      const savedStore = getStore();
      if (!savedStore) return payload;
      return savedStore;

    case "UPDATE_BUYER":
      newState = { ...state, buyer: { ...state.buyer, ...payload } };
      setStore(newState);
      return newState;

    case "UPDATE_BUYER_SELFDISCLOSURE":
      newState = {
        ...state,
        buyer: {
          ...state.buyer,
          selfdisclosure: { ...state.buyer.selfdisclosure, ...payload },
        },
      };
      setStore(newState);
      return newState;

    case "UPDATE_OWNER":
      newState = { ...state, owner: { ...state.owner, ...payload } };
      setStore(newState);
      return newState;

    case "UPDATE_BUYER_IS_OWNER":
      newState = { ...state, buyerIsOwner: payload };
      setStore(newState);
      return newState;

    default:
      return state;
  }
};

export default CheckoutStoreReducer;

const setStore = (state: TCheckoutStore): boolean => {
  localStorage.setItem("checkout", JSON.stringify(state));
  return true;
};

const getStore = (): TCheckoutStore | false => {
  const raw = localStorage.getItem("checkout");
  if (!raw) return false;
  const store: TCheckoutStore = JSON.parse(raw);
  return store;
};
