import React from "react";

import { CatalogStore } from "stores";

import Chip from "../../ChipIndicator";

interface IModellIndicator {
  filter: number[];
  dispatch?: any;
}

const ModellIndicator = ({
  filter,
  dispatch,
}: IModellIndicator): JSX.Element | null => {
  const {
    state: {
      entities: { modell },
    },
  } = React.useContext(CatalogStore);
  if (!filter.length) return null;

  const handleDelete = (modell: any) => {
    dispatch({
      type: "REMOVE_ID_FROM_FILTER",
      payload: { filter: "modell", id: modell },
    });
  };
  return (
    <>
      {filter.map((id: number) => (
        <Chip
          id={modell}
          label={modell[id].name}
          onDelete={() => handleDelete(id)}
        />
      ))}
    </>
  );
};

export default ModellIndicator;
