import React from "react";
import * as d3 from "d3-array";
import { useIntl } from "react-intl";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import { vehicle as defaultVehicle } from "fixtures";
import { TVehicle } from "types";

interface IOptions {
  vehicle: TVehicle;
}

const TabbedOptions: React.FC<IOptions> = ({
  vehicle = defaultVehicle,
}): JSX.Element | null => {
  const [activeTab, setActiveTab] = React.useState();
  const { formatMessage: t } = useIntl();

  if (!vehicle.ausstattung) return null;

  const groupedOptions = d3.group(
    vehicle.ausstattung,
    (d: any) => d.ausstattungsgruppe.id
  );

  const handleChange = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const firstKey = Array.from(groupedOptions.keys())[0];
  return (
    <Box>
      <Tabs
        value={activeTab || firstKey}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{
          backgroundColor: "grey.300",
          color: "white",
          maxWidth: { xs: 320, sm: 740, md: "inherit" },
        }}
      >
        {Array.from(groupedOptions, ([key, values]) => {
          return (
            <Tab
              key={key}
              value={key}
              label={t({ id: `ausstattungsgruppe.${key}.label` })}
            />
          );
        })}
      </Tabs>

      {Array.from(groupedOptions, ([key, options]) => {
        return (
          <Box
            sx={{ m: 1, minHeight: 300, overflow: "scroll" }}
            key={`optionGroup-${key}`}
            role="tabpanel"
            hidden={!(activeTab === key || (!activeTab && firstKey === key))}
          >
            <Stack sx={{ flexWrap: "wrap" }} direction="row">
              {options.map(({ id }) => (
                <Chip
                  key={`option-${id}`}
                  sx={{ m: 1, border: 1, borderColor: "grey.500" }}
                  label={t({ id: `ausstattung.${id}.label` })}
                />
              ))}
            </Stack>
          </Box>
        );
      })}
    </Box>
  );
};

export const StackedOptions: React.FC<IOptions> = ({
  vehicle = defaultVehicle,
}): JSX.Element | null => {
  const { formatMessage: t } = useIntl();

  if (!vehicle.ausstattung) return null;

  return (
    <Stack sx={{ flexWrap: "wrap" }} direction="row">
      {vehicle.ausstattung.map(({ id }: any) => (
        <Chip
          key={`option-${id}`}
          sx={{ m: 1, border: 1, borderColor: "grey.500" }}
          label={t({ id: `ausstattung.${id}.label` })}
        />
      ))}
    </Stack>
  );
};

export default TabbedOptions;
