import React from "react";
import { useIntl } from "react-intl";
import CircleIcon from "@mui/icons-material/Circle";
import Chip from "../../ChipIndicator";

interface IFarbeIndicator {
  filter: number[] | string[];
  dispatch?: any;
}

const FarbeIndicator = ({
  filter = [],
  dispatch,
}: IFarbeIndicator): JSX.Element | null => {
  const { formatMessage: t } = useIntl();
  if (!filter.length) return null;

  const handleDelete = (farbe: any) => {
    dispatch({
      type: "REMOVE_ID_FROM_FILTER",
      payload: { filter: "farbe_aussen", id: farbe },
    });
  };
  return (
    <>
      {filter.map((farbe: string | number) => (
        <Chip
          icon={
            <CircleIcon
              sx={{
                "&&": {
                  color: t({
                    id: `farbe.${farbe}.hex`,
                  }),
                },
              }}
            />
          }
          id={farbe}
          label={t({
            id: `farbe.${farbe}.label`,
          })}
          onDelete={() => handleDelete(farbe)}
        />
      ))}
    </>
  );
};

export default FarbeIndicator;
