const CatalogReducer = (state: any, action: { type: any; payload: any }) => {
  const { type, payload } = action;
  switch (type) {
    case "INIT":
      return { ...payload };

    default:
      return state;
  }
};

export default CatalogReducer;
