import React from "react";
import { FormattedMessage } from "react-intl";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { TVehicle } from "types";

import { vehicle as defaultVehicle } from "fixtures";

import TabbedOptions, { StackedOptions } from "./Tabs";

interface IOptions {
  vehicle: TVehicle;
  paperProps?: any;
  variant?: string;
}

const Options: React.FC<IOptions> = ({
  paperProps = {},
  vehicle = defaultVehicle,
}): JSX.Element => {
  return (
    <Paper variant="outlined" {...paperProps}>
      <Typography variant="h5">
        <FormattedMessage
          id="pages.detail.ausstattung.label"
          defaultMessage="Ausstattung"
        />
      </Typography>

      {vehicle.ausstattung.length > 10 ? (
        <TabbedOptions vehicle={vehicle} />
      ) : (
        <StackedOptions vehicle={vehicle} />
      )}
    </Paper>
  );
};

export default Options;
