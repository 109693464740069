import { schema } from "normalizr";

import { bankSchema } from "./Bank";

const financeSchema = new schema.Entity(
  "finance",
  {
    mileageLease: { bank: bankSchema },
    credit: { bank: bankSchema },
    varioFinancing: { bank: bankSchema },
  },
  { idAttribute: "id" }
);

const financeListSchema = new schema.Array(financeSchema);

export { financeSchema, financeListSchema };
