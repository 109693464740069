import { TReactHookForm } from "types";
import { ReactHookFormText, ReactHookFormSelect } from "components";

import { Container } from "..";

const LivingConditionsForm = ({
  register,
  control,
  errors,
  prefix = "livingConditions.",
}: TReactHookForm): JSX.Element => {
  return (
    <Container>
      <ReactHookFormSelect
        control={control}
        name="maritalStatus"
        prefix={prefix}
        labelPrefix="maritalStatus"
        error={errors?.maritalStatus?.type}
        required
        sort={false}
        options={[
          "single",
          "married",
          "divorced",
          "widowed",
          "registeredLifePartnership",
        ]}
      />
      <ReactHookFormSelect
        control={control}
        error={errors?.propertyType?.type}
        sort={false}
        required
        name="propertyType"
        prefix={prefix}
        labelPrefix="propertyType"
        options={[
          "apartmentForRent",
          "condominium",
          "houseForRent",
          "homeownership",
        ]}
      />
      <ReactHookFormText
        register={register}
        defaultValue={0}
        name="dependentChildren"
        required
        prefix={prefix}
        error={errors?.dependentChildren?.type}
        inputProps={{ min: 0 }}
        type="number"
      />
    </Container>
  );
};

export default LivingConditionsForm;
