import React from "react";

import Box from "@mui/material/Box";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";

import { TAusstattung } from "types";

import { vehicle } from "fixtures";
import { FormattedMessage } from "react-intl";

interface ITopicFeatures {
  topFeatures: TAusstattung[];
}

const features = vehicle.ausstattung.slice(0, 10);

const TopicFeatures: React.FC<ITopicFeatures> = ({
  topFeatures = features,
}): JSX.Element => (
  <Box
    sx={{
      typography: "body2",
      color: "grey.600",
      display: "grid",
      rowGap: 1,
      gridTemplateColumns: {
        xs: "repeat(1, 1fr)",
      },
    }}
  >
    {topFeatures.map(({ id }: { id: number }) => (
      <Box key={id}>
        <CheckCircleSharpIcon color="inherit" fontSize="inherit" />
        &nbsp;
        <FormattedMessage id={"ausstattung." + id + ".label"} />
      </Box>
    ))}
  </Box>
);

export default TopicFeatures;
