import { TVehicle } from "types";

interface IAufbauReducer {
  vehicles: TVehicle[];
  filter: any;
}

const AufbauReducer = ({ vehicles, filter }: IAufbauReducer) => {
  if (!filter.aufbau.length) return vehicles;
  return vehicles.filter(({ aufbau }) => {
    return aufbau && filter.aufbau && filter.aufbau.includes(aufbau.id);
  });
};

export default AufbauReducer;
