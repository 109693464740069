import * as d3 from "d3-array";

import { TVehicle } from "types";

interface IAusstattungReducer {
  vehicles: TVehicle[];
  filter: any;
}

const AusstattungReducer = ({ vehicles, filter }: IAusstattungReducer) => {
  if (!filter.ausstattung.length) return vehicles;
  return vehicles.filter(({ ausstattung }) =>
    d3.subset(
      filter.ausstattung,
      ausstattung.map(({ id }) => id)
    )
  );
};

export default AusstattungReducer;
