import { TVehicle } from "types";

interface IKraftstoffReducer {
  vehicles: TVehicle[];
  filter: any;
}

const KraftstoffReducer = ({ vehicles, filter }: IKraftstoffReducer) => {
  if (!filter.kraftstoff.length) return vehicles;
  return vehicles.filter(({ kraftstoff }) => {
    return (
      kraftstoff &&
      filter.kraftstoff &&
      filter.kraftstoff.includes(kraftstoff.id)
    );
  });
};

export default KraftstoffReducer;
