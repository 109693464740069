import React, { ReactNode, useReducer } from "react";

import CartReducer from "./CartReducer";
import { TCart } from "./types";
import { cart as testCart } from "fixtures";

type TCartProvider = {
  config?: { type: string };
  children: ReactNode;
};

const emptyCartStore: TCart = { vehicles: [] };

export const CartStore = React.createContext<{
  state: TCart;
  dispatch: React.Dispatch<any>;
}>({
  state: emptyCartStore,
  dispatch: () => null,
});

const CartProvider = ({
  config = { type: "prod" },
  children,
}: TCartProvider) => {
  const [state, dispatch] = useReducer(CartReducer, emptyCartStore);
  React.useEffect(() => {
    getData(config)(dispatch);
  }, [config]);

  return (
    <CartStore.Provider value={{ state, dispatch }}>
      {children}
    </CartStore.Provider>
  );
};

export default CartProvider;

const getData =
  ({ type }: { type: string }): any =>
  async (dispatch: any) => {
    switch (type) {
      case "test":
        dispatch({ type: "REPLACE_VEHICLES", payload: testCart.vehicles });
        break;

      default:
        dispatch({ type: "RESET_VEHICLES" });
    }
  };
