import { TCart, TCartVehicle } from "stores/types";

const CartReducer = (
  state: TCart,
  action: { type: any; payload: TCartVehicle }
) => {
  const { type, payload } = action;

  switch (type) {
    case "ADD_VEHICLE":
      state.vehicles.push(payload);
      return state;

    case "REPLACE_VEHICLES":
      return {
        ...state,
        vehicles: Array.isArray(payload) ? payload : [payload],
      };

    case "RESET_VEHICLES":
      return { ...state, vehicles: [] };

    default:
      return state;
  }
};

export default CartReducer;
