import { denormalize } from "normalizr";
import { vehicleListSchema } from "schemas";

import { array2normalized } from "./helper";

import vehicleData from "./vehicle.json";

const entities = { vehicle: {} };

const id = vehicleData.vehicle.wgnr;
for (const [key, input] of Object.entries(vehicleData)) {
  if (key === "vehicle") continue;
  entities[key] = array2normalized({ input });
}

entities.vehicle[id] = vehicleData.vehicle;

const vehicle = denormalize([id], vehicleListSchema, entities)[0];

export default vehicle;
