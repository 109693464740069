import { TReactHookForm } from "types";
import { ReactHookFormText } from "components";
import { ReactHookFormSelect } from "components";

import { Container } from "..";

const NameForm = ({
  register = () => null,
  control = () => null,
  errors = {},
}: TReactHookForm): JSX.Element => {
  return (
    <Container>
      <ReactHookFormSelect
        options={["m", "f", "other"]}
        control={control}
        name="gender"
        error={errors?.gender?.type}
        required
        sort={false}
      />
      <ReactHookFormText
        register={register}
        name="firstName"
        error={errors?.firstName?.type}
      />
      <ReactHookFormText
        register={register}
        name="lastName"
        error={errors?.lastName?.type}
      />
      <ReactHookFormText
        register={register}
        name="birthName"
        error={errors?.birthName?.type}
        required={false}
      />
    </Container>
  );
};

export default NameForm;
