import { createTheme } from "@mui/material/styles";

export const defaultTheme = createTheme();

const carbandoTheme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: false,
    },
  },
  typography: {
    h1: {
      fontFamily: "Montserrat, Roboto, Arial, Helvetica",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: "Montserrat, Roboto, Arial, Helvetica",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h3: {
      fontFamily: "Montserrat, Roboto, Arial, Helvetica",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h4: {
      fontFamily: "Montserrat, Roboto, Arial, Helvetica",
      fontWeight: 700,
    },
    h5: {
      fontFamily: "Montserrat, Roboto, Arial, Helvetica",
      fontWeight: 700,
    },
    h6: {
      fontFamily: "Montserrat, Roboto, Arial, Helvetica",
      fontWeight: 700,
    },
    body1: {
      fontFamily: "Roboto, Arial, Helvetica",
    },
    body2: {
      fontFamily: "Roboto, Arial, Helvetica",
      color: "#999999",
    },
    button: {
      fontFamily: "Montserrat, Roboto, Arial, Helvetica",
      fontWeight: 600,
      textTransform: "uppercase",
    },
  },
  palette: {
    primary: {
      light: "#D0585C",
      main: "#BC1018",
      dark: "#990A08",
    },
    secondary: {
      light: "#636363",
      main: "#4D4D4D",
      dark: "#333333",
    },
    background: {
      default: "#EEE",
    },
    header: {
      color: "#CCCCCC",
      backgroundColor: "#333333",
    },
    footer: {
      color: "#CCCCCC",
      backgroundColor: "#333333",
    },
  },
});

export default carbandoTheme;
