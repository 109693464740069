import dayjs from "dayjs";

import { TReactHookForm } from "types";
import { ReactHookFormText, ReactHookFormSelect } from "components";
import { Container } from "..";

import { countries } from "fixtures";

type TAddressForm = TReactHookForm & {
  showResidentSince: boolean;
};

const AddressForm = ({
  register,
  control,
  errors,
  prefix = "address.",
  showResidentSince = true,
}: TAddressForm): JSX.Element => {
  return (
    <Container>
      <ReactHookFormText
        register={register}
        prefix={prefix}
        name="street"
        error={errors?.street?.type}
      />
      <ReactHookFormText
        register={register}
        prefix={prefix}
        name="houseNumber"
        error={errors?.houseNumber?.type}
      />
      <ReactHookFormText
        register={register}
        prefix={prefix}
        name="additionalAddress"
        error={errors?.additionalAddress?.type}
        required={false}
      />
      <ReactHookFormText
        register={register}
        prefix={prefix}
        name="zipCode"
        error={errors?.zipCode?.type}
      />
      <ReactHookFormText
        register={register}
        prefix={prefix}
        name="city"
        error={errors?.city?.type}
      />
      <ReactHookFormSelect
        control={control}
        prefix={prefix}
        name="country"
        options={countries}
        labelPrefix="country"
        featured={["de", "gb", "tr", "at", "ch"]}
        required
        error={errors?.country?.type}
      />
      {showResidentSince && (
        <ReactHookFormText
          register={register}
          name="residentSince"
          prefix={prefix}
          error={errors?.residentSince?.type}
          required={true}
          type="date"
          inputProps={{
            max: dayjs().format("YYYY-MM-DD"),
            min: dayjs().subtract(100, "year").format("YYYY-MM-DD"),
          }}
        />
      )}
    </Container>
  );
};

export default AddressForm;
