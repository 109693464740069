import { TVehicle } from "types";

interface IFzartReducer {
  vehicles: TVehicle[];
  filter: any;
}

const FzartReducer = ({ vehicles, filter }: IFzartReducer) => {
  if (!filter.fzart.length) return vehicles;
  return vehicles.filter(({ fzart }) => {
    return fzart && filter.fzart && filter.fzart.includes(fzart.id);
  });
};

export default FzartReducer;
