import React from "react";
import Link from "@mui/material/Link";

interface IFootnote {
  id: number;
}

const Footnote: React.FC<IFootnote> = ({ id = 1 }): JSX.Element => (
  <Link
    href={`#footnote-${id}`}
    onClick={(e) => e.stopPropagation()}
    sx={{ textDecoration: "none", fontWeight: "bold" }}
  >
    <sup>{id}</sup>
  </Link>
);

export default Footnote;
