import React from "react";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { IntlContext, allowed_locales } from "intl";

const LanguageSwitch: React.FC = (): JSX.Element => {
  const { locale, setLocale } = React.useContext(IntlContext);
  const handleChange = (event: SelectChangeEvent) => {
    setLocale(event.target.value as string);
  };
  return (
    <Select
      sx={{
        height: 40,
        borderRadius: 1,
        backgroundColor: "grey.400",
      }}
      value={locale}
      onChange={handleChange}
    >
      {allowed_locales.map((lang) => (
        <MenuItem key={lang} value={lang}>
          {lang}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSwitch;
