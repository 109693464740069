import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { App, Configuration } from "containers";
import { Intl } from "intl";
import { Theme } from "themes";
import {
  CatalogProvider,
  CartProvider,
  FilterProvider,
  VehicleProvider,
  CheckoutStoreProvider,
  CookieProvider,
} from "stores";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <Configuration>
      <Theme>
        <Intl>
          <BrowserRouter>
            <CookieProvider config={{ type: "prod" }}>
              <CatalogProvider config={{ type: "prod" }}>
                <CartProvider config={{ type: "prod" }}>
                  <CheckoutStoreProvider config={{ type: "prod" }}>
                    <FilterProvider config={{ type: "prod" }}>
                      <VehicleProvider>
                        <App />
                      </VehicleProvider>
                    </FilterProvider>
                  </CheckoutStoreProvider>
                </CartProvider>
              </CatalogProvider>
            </CookieProvider>
          </BrowserRouter>
        </Intl>
      </Theme>
    </Configuration>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
